import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import _ from 'lodash';
import useSettings from '../hook/useSettings';
import useShops from '../utility/useShops';
import { useIntl, FormattedMessage } from 'react-intl';
import ShopsCredentialsDialog from './ShopsCredentialsDialog';
import { fetchApiPost } from '../utility/fetchApi';
import useShowMessage from '../hook/useShowMessage';
import DeleteIcon from '@mui/icons-material/Delete';

export default function ShopConfigurationForm({ onError }) {
  const shops = useShops({ onError });
  const intl = useIntl();
  const [shopDialogOpened, setShopDialogOpened] = useState(false);
  const [selectedShop, setSelectedShop] = useState();
  const [shoMessage, setShowMessage] = useShowMessage();

  const [settings, setSettings] = useSettings();
  const [prefferedShopId, setPrefferedShopId] = useState();

  useEffect(() => {
    if (!_.isEmpty(settings)) {
      setPrefferedShopId(settings.prefferedShopId?.value);
    }
  }, [settings?.prefferedShopId]);

  const deleteShopSettings = async (shop) => {
    try {
      const result = await fetchApiPost('userSecret/removeUserSecretWrapper', { shopId: shop.shopId });
      let newSettings = { ...settings, shopConfiguration: {} };    
      newSettings.shopConfiguration.value = {}
      newSettings.shopConfiguration.scope = 'server';
      Object.keys(settings.shopConfiguration.value).forEach((key) => {
        if (key != shop.shopId) {
          newSettings.shopConfiguration.value[key] = settings.shopConfiguration.value[key];
        }
      });
      setSettings(newSettings);
      setShowMessage({ message: intl.formatMessage({
        id: 'userSettings.shopConfigurationForm.configurationRemoved',
        defaultMessage: `Configuration for selected shop was removed.`,
      }), severity: `success` });
    } catch (e) {
      setShowMessage({ message: intl.formatMessage({
        id: 'userSettings.shopConfigurationForm.configurationRemovalFailed',
        defaultMessage: `Unable to remove configuration.`,
      }) , severity: `error` });
    }
  };

  return (
    <Box>
      <ShopsCredentialsDialog
        open={shopDialogOpened}
        handleClose={() => setShopDialogOpened(false)}
        shop={selectedShop}
      />
      <FormControl fullWidth sx={{ padding: '16px' }}>
        {(shops ?? []).map((shop) => (
          <Box>
            <Box display={'flex'} flexDirection={'row'} sx={{ padding: '16px', justifyContent: 'space-between' }}>
              <Box display={'flex'} sx={{ alignItems: 'flex-end' }}>
                <Box display={'flex'} flexDirection={'row'}>
                  <img src={shop.icon32} width="32px" alt={`${shop.name} icon`} />
                  <Box sx={{ padding: '8px' }}>{shop?.name} </Box>
                </Box>
              </Box>
              <Box sx={{ padding: '4px' }}>
                {shop?.connectSupported ? (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShopDialogOpened(true);
                        setSelectedShop(shop);
                      }}
                    >
                      {_.isEmpty(settings?.shopConfiguration?.value?.[shop.shopId]) ? (
                        <FormattedMessage id="userSettings.shopConfigurationForm.Connect" defaultMessage="Connect" />
                      ) : (
                        <FormattedMessage
                          id="userSettings.shopConfigurationForm.Reconfigure"
                          defaultMessage="Reconfigure"
                        />
                      )}
                    </Button>
                    {!_.isEmpty(settings?.shopConfiguration?.value?.[shop.shopId]) && (                    
                      <IconButton
                      sx={{ marginLeft: '16px', color: 'red' }}
                      onClick={() => {
                        deleteShopSettings(shop);
                      }}                      
                    >
                      <DeleteIcon />
                    </IconButton>
                    )}
                  </Box>
                ) : (
                  <FormattedMessage
                    id="userSettings.shopConfigurationForm.connectNotSupportedYet"
                    defaultMessage="Connect not supported yet"
                  />
                )}
              </Box>
            </Box>
            <Divider sx={{ marginRight: '32px' }} />
          </Box>
        ))}
      </FormControl>
      <FormControl fullWidth sx={{ marginLeft: '16px', marginBottom: '16px' }}>
        <InputLabel id="demo-simple-select-label">
          <FormattedMessage id="userSettings.shopConfigurationForm.PrefferedShop" defaultMessage="Prefered shop" />
        </InputLabel>
        <Select
          id="demo-simple-select"
          value={prefferedShopId ?? ''}
          label={intl.formatMessage({
            id: 'userSettings.shopConfigurationForm.PrefferedShop',
            defaultMessage: `Prefered shop`})
          }
          sx={{ marginRight: '32px' }}
          onChange={(event) => {
            if (event.target.value === 0) {
              const newSettings = { ...settings };
              delete newSettings.prefferedShopId;
              setSettings(newSettings);
            } else {
              setSettings({ ...settings, prefferedShopId: { value: event.target.value, scope: 'server' } });
            }
          }}
        >
          <MenuItem key={`prefferedShopMenuItem0`} value={0} selected={settings?.prefferedShopId?.value === undefined}>
            &nbsp;
          </MenuItem>
          {(shops ?? []).map((item) => (
            <MenuItem
              key={`prefferedShopMenuItem${item.shopId}`}
              value={item.shopId}
              selected={item.shopId == settings?.prefferedShopId?.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
