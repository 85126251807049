import { BrowserRouter, Switch, Route } from 'react-router-dom';
import BrowserExtensionPage from './pages/BrowserExtensionPage';
import FindProductPage from './pages/FindProductPage';
import ListDetailPage from './pages/ListDetailPage';
import ListsPage from './pages/ListsPage';
import ShopDetailPage from './pages/ShopDetailPage';
import ShopsPage from './pages/ShopsPage';
import UserSettingsPage from './pages/UserSettingsPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import ListDetailPageAutoAdd from './pages/ListDetailPageAutoAdd';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/lists/:listId/:recipeUrl" render={() => <ListDetailPage />} />
        <Route path="/autoadd/:listId/:recipeUrl" render={() => <ListDetailPageAutoAdd />} />
        <Route path="/lists/:listId" render={() => <ListDetailPage />} />
        <Route path="/lists/" render={() => <ListsPage />} />
        <Route path="/shops/:shopId" /*component={ShopDetailPage}*/ render={() => <ShopDetailPage />} />
        <Route path="/shops/" render={() => <ShopsPage />} /*component={ShopsPage}*/ />
        <Route path="/userSettings/:section" render={() => <UserSettingsPage />} />
        <Route path="/userSettings/" render={() => <UserSettingsPage />} />
        <Route path="/find-product-for-ingredient/:shopId/:rowId" render={() => <FindProductPage />} />
        <Route path="/browserExtension" render={() => <BrowserExtensionPage />} />
        <Route path="/authentication/login" render={() => <LoginPage />} />
        <Route path="/authentication/logout" render={() => <LogoutPage />} />
        <Route path="/" render={() => <ListsPage />} />
      </Switch>
    </BrowserRouter>
  );
}

export default AppRoutes;
