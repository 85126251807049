const _ = require('lodash');
const apiBase = process.env.REACT_APP_API_BASE || `${window.location.origin}/api`;

function mergeOptions(fixedOptions, options) {
  const optionsCopy = { ...options };
  if ('headers' in optionsCopy) {
    delete optionsCopy.headers;
  }
  if ('auth' in optionsCopy) {
    delete optionsCopy.auth;
    if (options.auth && options.auth.accountId) {
      if (!options.headers) options.headers = {};
      options.headers['x-account-id'] = options.auth.accountId;
    }
  }

  return {
    ...fixedOptions,
    ...optionsCopy,
    headers: {
      ...fixedOptions.headers,
      ...(options.headers || {}),
    },
  };
}

function encodeParams(params) {
  const paramsCopy = { ...params };
  for (const key of _.keys(params)) {
    const value = params[key];
    if (_.isPlainObject(value)) {
      for (const subkey of _.keys(value)) {
        paramsCopy[`${key}[${subkey}]`] = value[subkey];
      }
      delete paramsCopy[key];
    }
  }
  return _.keys(paramsCopy)
    .filter((key) => paramsCopy[key] != null)
    .map((key) => `${key}=${encodeURIComponent(paramsCopy[key])}`)
    .join('&');
}

export async function fetchApiGet(url, params, options = {}) {
  if (url == null) return undefined;
  options = mergeOptions(options, getAuthHeader());
  const apikey = '0abad3c2-5595-4653-bcf2-06f29a848d2b';
  const paramsText = encodeParams(params);
  const resp = await fetch(
    `${apiBase}/${url}?${paramsText}`,
    mergeOptions(
      {
        method: 'GET',
        headers: {
          'x-api-key': apikey,
        },
      },
      options
    )
  );
  const data = await resp.json();
  handleErrors(resp, data);
  return data;
}

export async function fetchApiPost(url, data, options = {}, onFailed = null) {
  if (url == null) return undefined;
  try {
    options = mergeOptions(options, getAuthHeader());
    const apikey = '0abad3c2-5595-4653-bcf2-06f29a848d2b';
    const resp = await fetch(
      `${apiBase}/${url}`,
      mergeOptions(
        {
          method: 'POST',
          headers: {
            'x-api-key': apikey,
            'content-type': 'application/json',
          },
          body: data && JSON.stringify(data),
        },
        options
      )
    );
    const textResult = await resp.text();
    try{
      const dataResp = JSON.parse(textResult);
      if(!_.isObject(dataResp)){
        throw new Error(`Invalid response recieved from server when calling ${apiBase}/${url}. Response was ${textResult}`)
      }
      handleErrors(resp, dataResp);
      return dataResp;
    } catch(error){
      console.error(`Error handling response with message: \n ${error}. Response: \n ${JSON.stringify(resp)}`)
      throw error;
    }
    
  } catch (error) {
    if (_.isFunction(onFailed)) {      
      onFailed(error);
    } else {
      throw error;
    }
  }  
}

export function getApiBase() {
  return apiBase;
}

function getAuthHeader() {
  const userToken = getBearer();
  if (userToken !== null) {
    return {
      headers: {
        authorization: `Bearer:${userToken}`,
      },
    };
  } else {
    return {};
  }
}

function getBearer() {
  const usd = JSON.parse(localStorage.getItem('user'));
  if (usd) {
    return usd.bearer;
  } else {
    return null;
  }
}

function handleErrors(resp, data) {
  const error = { status: resp.status };
  if (resp.status === 401 && data.type === 'NO_TOKEN') {
    error.message = 'Authorization required. No token. To access the page, please log in.';
    console.log(JSON.stringify(error));
    throw error;
  } else if (resp.status === 401 && data.type === 'INVALID_TOKEN') {
    error.message = 'Authorization required. Your token is invalid. Try to login again.';
    console.log(JSON.stringify(error));
    throw error;
  } else if (resp.status === 500) {
    error.message = `Something went wrong. Please try again later. Internal error: ${JSON.stringify(data.message)}`;
    console.log(JSON.stringify(error));
    throw error;
  } else if (resp.status === 504) {
    error.message = `Gateway timeout error. Please try again later.`;
    console.log(JSON.stringify(error));
    throw error;
  } else if (resp.status !== 200) {
    error.message = `Unknown error ${resp.status}. Please try again later. Internal error: ${JSON.stringify(data.message)}`;
    console.log(JSON.stringify(error));
    throw error;    
  }
}
