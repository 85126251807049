import { useEffect } from 'react';
import useUser from '../../utility/useUser';
import { settingsServerStoredState } from '../../utility/atoms';
import { useRecoilState } from 'recoil';
import useSettings from '../../hook/useSettings';
import { getServerStoredSettings, mergeServerSettings } from '../../lib/settings';

function ServerStoredSettingsProvider() {
  const [user, setUser] = useUser({ loginRequired: false });
  const [settings, setSettings] = useSettings();
  const [eventSourceSettings, setEventSourceSettings] = useRecoilState(settingsServerStoredState);

  useEffect(() => {
    if (user?.loginStatus !== 'loggedIn') return;
    const loadSettings = async () => {
      const serverSettings = await getServerStoredSettings();
      if (serverSettings) {
        const mergedSettings = mergeServerSettings(serverSettings, settings);
        setSettings({ value: mergedSettings, param: { suppressServerSave: true } });
      }
    };
    loadSettings();
  }, [user?.loginStatus]);

  useEffect(() => {
    try {
      if (eventSourceSettings) {
        const mergedSettings = mergeServerSettings(eventSourceSettings.content, settings);
        setSettings({ value: mergedSettings, param: { suppressServerSave: true } });
      }
    } catch (error) {
      console.log(error);
    }
  }, [eventSourceSettings]);

  return <div />;
}

export default ServerStoredSettingsProvider;
