import Box from '@mui/material/Box';
import _ from 'lodash';
import React from 'react';
import { shopsLib } from '../utility/shops';

export default function ProductImage({ product, maxWidth = 60, maxHeight = 60 }) {
  if (_.isNil(product) || !(product.productUrl || product.productURL)) return null;
  const productUrl = product.productUrl ? product.productUrl : product.productURL;
  const imageUrl = product.imageUrl ? product.imageUrl : product.imageURL;
  //console.log(JSON.stringify(product));
  const shopName = shopsLib.getShopNameFromUrl(productUrl);
  const src =
    shopName === 'kosik.cz'
      ? imageUrl?.replace('WIDTH', maxWidth * 10).replace('HEIGHT', 10*maxHeight)
      : imageUrl;
  return (
    <Box sx={{ width: maxWidth, alignContent: "center", justifyContent: "center", display: "flex"}} >
      <Box component="img" alt={`${product?.name}`} src={src} sx={{ maxWidth, maxHeight }}/>
    </Box>
  );
}
