import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

import useShoppingList from '../utility/useShoppingList';
import usePrefferedShop from '../utility/usePrefferedShop';
import { drawerWidth } from '../utility/useViewportSize';
import SystemMessageHandler from '../ui-elements/SystemMessageHandler';
import { Alert, Avatar, Box, Divider, Menu, MenuItem } from '@mui/material';
import useUser from '../utility/useUser';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  hide: {
    visibility: 'hidden',
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  content: {
    left: 0,
    right: 0,
    marginBottom: 60,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  language: {
    marginTop: '5px',
    textTransform: 'uppercase',
    fontSize: '110%',
  },
  footer: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    height: '70px',
    display: 'flex',
    padding: '0px',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    borderTop: '1px solid #ccc',
  },
}));

/*function getLanguage(i18n) {
  const { languages } = i18n;
  for (const lang of languages || []) {
    if (lang === 'cs' || lang === 'en') return lang;
  }
  return 'en';
}

const availableLanguages = [
  { title: 'English', code: 'en' },
  { title: 'Česky', code: 'cs' },
];*/

function AppLayout({
  title,
  t_title,
  children,
  mainIconMeaning,
  customButtons,
  showRefreshButton,
  shareMenuItems,
  subMenuItems,
  parentRoute,
  showLanguageSelector,
  appBarOverlay,
  appBarBackground,
  appBarTextColor,
  snackState,
  setSnackState,
  absoluteAppBar,
  userAvatar,
  // appBarPosition = 'sticky',
}) {
  const classes = useStyles();
  const [user, setUser] = useUser({ loginRequired: true });
  const history = useHistory();
  const [showUserDetailMenu, setShowUserDetailMenu] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setShowUserDetailMenu(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setShowUserDetailMenu(null);
  };
  const [prefferedShop, setPrefferedShop] = usePrefferedShop({ onError: null });
  const [shoppingList, setShoppingList] = useShoppingList();
  const intl = useIntl();

  const shopName = prefferedShop?.name ?? 'kosik.cz';
  const totalAmount = `${_.sumBy(
    shoppingList?.content?.filter((item) => item[shopName]),
    (ingr) => ingr[shopName].price * ingr[shopName].amount
  ).toFixed(0)},-`;

  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);

  const handleMenuClick = () => {
    if (mainIconMeaning === 'back') {
      if (window.history.length > 2) history.goBack();
      else history.push('/');
      return;
    } else if (mainIconMeaning === 'lists') {
      history.push(`/${mainIconMeaning}`);
      return;
    }

    // if (groupNestingLevel > 0 && mainIconMeaning !== 'menu') {
    //   history.goBack();
    //   return;
    // }

    switch (mainIconMeaning) {
      case 'back':
        // history.goBack();
        history.push(parentRoute);
        break;
      case 'menu':
        setIsDrawerOpened(true);
        break;
      case 'top':
        history.push('/');
        break;
      default:
        history.goBack();
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <Paper elevation={0} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {!appBarOverlay && (
            //<div className={classes.hide}>
            //  <AppBar position="static">
            //    <Toolbar />
            //  </AppBar>
            //</div>
            <div />
          )}
          <AppBar
            position={/*absoluteAppBar ? 'absolute' : 'fixed'*/ 'static'}
            className={classes.appBar}
            style={{ background: "#FFFFFF", color: appBarTextColor }}
            sx={{
              width: '100%',
              maxWidth: 1920,
              mx: 'auto',
            }}
          >
            <Toolbar>
            <img src='../../images/logo.png' width="48px" alt={`YumDroid Logo icon`} />
              {/* <Hidden mdUp>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={handleMenuClick}>
              {mainIconMeaning === 'menu' ? <MenuIcon /> : <ArrowBack />}
            </IconButton>
          </Hidden> */}
              {(mainIconMeaning === 'back' || mainIconMeaning === 'lists') && (
                <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={handleMenuClick} sx={{marginLeft: '0em'}} >
                  <ArrowBack />
                </IconButton>
              )}              
              <Typography variant="h6" color="inherit" className={classes.title} m={1}>
                {title}
              </Typography>
              {/* {showRefreshButton && (
            <IconButton onClick={callRefresh} color="inherit" title={'Refresh'}>
              <RefreshIcon />
            </IconButton>
          )} */}
              <Box key="userMenu" sx={{ whiteSpace: 'nowrap' }}>
                <Menu
                  sx={{ mt: '45px' }}
                  id="userMenu-appbar"
                  anchorEl={showUserDetailMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(showUserDetailMenu)}
                  onClose={handleCloseUserMenu}
                >
                  <Box display={'flex'} flexDirection={'column'} sx={{ padding: '16px', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', paddingBottom: '10px' }}>
                      <Avatar />
                    </Box>

                    <Typography textAlign="center">
                      <FormattedMessage id="menu.name" defaultMessage="Name" />: {user?.name}
                    </Typography>
                    <Typography textAlign="center">
                      <FormattedMessage id="menu.email" defaultMessage="Email" />: {user?.email}
                    </Typography>
                  </Box>

                  <Divider />
                  <MenuItem
                    onClick={() => {
                      history.push('/userSettings');
                      setShowUserDetailMenu(false);
                    }}
                  >
                    <IconButton color="inherit">
                      <SettingsIcon />
                    </IconButton>
                    <Typography textAlign="center">
                      <FormattedMessage id="menu.settings" defaultMessage="Settings" />
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push('../authentication/logout');
                      setShowUserDetailMenu(false);
                    }}
                  >
                    <IconButton color="inherit">
                      <LogoutIcon />
                    </IconButton>
                    <Typography textAlign="center">
                      <FormattedMessage id="menu.logout" defaultMessage="Logout" />
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <IconButton
                onClick={handleOpenUserMenu}
                size="large"
                aria-label="display more actions"
                edge="end"
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>

              {customButtons}
            </Toolbar>
          </AppBar>
          <div style={{ minHeight: 'calc(100vh - 120px)' }}>
            <div className={classes.content}>{children}</div>
          </div>
          <Container
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
            maxWidth="xl"
            sx={{ position: 'fixed', bottom: 0 /*left: 0, right: 0*/ }}
          >
            <Paper elevation={3}>
              <BottomNavigation
                showLabels
                value={history.location.pathname}
                onChange={(event, newValue) => {
                  history.push(newValue);
                }}
              >
                {/* <BottomNavigationAction
                  label={intl.formatMessage({ id: 'menu.home', defaultMessage: 'Home' })}
                  value="/"
                  icon={<HomeIcon />}
                /> */}
                <BottomNavigationAction
                  label={intl.formatMessage({ id: 'menu.search', defaultMessage: 'Search' })}
                  value="/lists"
                  icon={<SearchIcon />}
                />
                {/* <BottomNavigationAction
                  label="Cart"
                  value="/shopping-list"
                  icon={
                    <Badge
                      variant={shoppingList ? 'standard' : 'dot'}
                      badgeContent={shoppingList?.content?.length}
                      color="secondary"
                    >
                      <ShoppingCartIcon />
                    </Badge>
                  }
                /> */}
                <BottomNavigationAction
                  label={intl.formatMessage({ id: 'menu.list', defaultMessage: 'List' })}
                  value={`/lists/${shoppingList?.listId ?? ''}`}
                  icon={
                    <Badge
                      variant={shoppingList ? 'standard' : 'dot'}
                      badgeContent={shoppingList?.content?.length}
                      color="secondary"
                    >
                      <FormatListBulletedIcon />
                    </Badge>
                  }
                />
                <BottomNavigationAction
                  label={prefferedShop ? '' : intl.formatMessage({ id: 'menu.shop', defaultMessage: 'Shop' })}
                  value={`/shops/${prefferedShop?.name ?? ''}`}
                  icon={
                    <Badge
                      badgeContent={`${totalAmount}`}
                      variant={shoppingList ? 'standard' : 'dot'}
                      color="secondary"
                    >
                      {!prefferedShop && <ShoppingBasketIcon />}
                      {prefferedShop && (
                        <img src={prefferedShop.icon32} width="32px" alt={`${prefferedShop.name} icon`} />
                      )}
                    </Badge>
                  }
                />
              </BottomNavigation>
            </Paper>
          </Container>
        </Paper>
      </Container>
    </div>
  );
}

export default AppLayout;
