import useList from './useList';
import { useEffect, useRef, useState } from 'react';
import useUser from './useUser';
import useSettings from '../hook/useSettings';
import { createEmptyList } from './listlib';
import { fetchApiPost } from './fetchApi';
import { SHOPPING_LIST_VERIFIED } from './const';
import _ from 'lodash';

const SHOPPING_LIST_TAG_ID = 2;

export default function useShoppingList(onError, param = { forceToCreate: true }) {
  const [settings, setSettings] = useSettings();
  const [user, setUser] = useUser({ loginRequired: false });
  const [list, setList] = useList({ onError, listId: settings?.activeShoppingListId?.value });
  const isLoadingRef = useRef(SHOPPING_LIST_VERIFIED); //Jena

  useEffect(() => {
    if (
      _.isUndefined(settings?.activeShoppingListId?.value) &&
      user?.loginStatus === 'loggedIn' &&
      !isLoadingRef.current
    ) {
      isLoadingRef.current = true;
      //force to create shopping list
      const createShoppingList = async () => {
        const lists = await fetchApiPost('list/findWrapper', { rootTagId: SHOPPING_LIST_TAG_ID, isPublic: false });
        let newShoppingList;
        if (_.isEmpty(lists)) {
          newShoppingList = await createEmptyList({ onError, rootTagId: SHOPPING_LIST_TAG_ID });
        } else {
          newShoppingList = lists.reduce((a, b) => (a.updatedOn > b.updatedOn ? a : b));
        }
        setSettings({ ...settings, activeShoppingListId: { scope: 'server', value: newShoppingList.listId } });
        setList(newShoppingList);
      };
      createShoppingList();
    } else if (user?.loginStatus === 'loggedOut') {
      setList({});
    }
  }, [settings, user]);

  const setShoppingList = (changeObject) => {
    //validate if there is no new shopping list set
    const newId = changeObject?.shoppingList?.listId ?? changeObject?.listId;
    if (newId && settings.activeShoppingListId !== newId) {
      setSettings({ ...settings, activeShoppingListId: {scope: 'server', value: newId}});
    }
    setList(changeObject);    
  };

  return [list, setShoppingList];

  /* 

  const refreshFunction = (param) => {
   // console.log(`Refresh function called with ${JSON.stringify(param)}`);
    if (!param) return;
    if ('action' in param) {
      if (['modifyContent', 'addIngredients'].includes(param.action)) {
        let content = [];
        if (_.isFunction(param.context)) {
          content = param.context(shoppingList.content ?? []);
        } else if (_.isArray(param.context)) {
          if(param.action === 'addIngredients'){
            content = param.context;
          }else{
            content = (shoppingList.content ?? []).concat(param.context);
          }          
        }
        const evaluateChange = async () => {
          console.log(`Evaluating shopping list changes.`);          
          //console.log(`Original shopping list is  ${JSON.stringify(shoppingList)}`);
          const extendedShoppingList = await makeListChange({oldList: shoppingList, newValues: content, onError, config:appSettings});          
          setShoppingList({ ...extendedShoppingList });
          //console.log(`New shopping list is  ${JSON.stringify(extendedShoppingList)}`);
          console.log(`Changes are evaluated. Shopping list age is ${new Date(extendedShoppingList.updatedOn).getTime()}`);
        };
        evaluateChange();
      }else if(['replaceProduct'].includes(param.action)){   
        const newProduct = param?.context?.newProduct;
        const ingredient = {...shoppingList.content.find((item) => item.rowId === param.context?.ingredient?.rowId)};        
        if(!newProduct || !ingredient) return;
        const shopId = shops.getShopNameFromUrl(newProduct?.productURL ?? newProduct?.productUrl);        
        const oldProduct = shoppingList.content.find((item) => item.rowId === ingredient.rowId)?.[shopId]        
        console.log(`Replacting product for ingredient ${ingredient.name}(${ingredient.rowId}) having product '${oldProduct?.product_name}' with product '${newProduct.product_name}'`);                
        productSwapFeedback({ingredient, newProduct, oldProduct, list: shoppingList});   
        const newRow = {...ingredient, [shopId]: newProduct, version: (ingredient.version + 1)};
        const newContent = shoppingList.content.map(item => item.rowId === ingredient.rowId ? newRow : item);    
        const evaluateChange = async () => {
          //TODO - make change needs to identify change in product for specific rowId
          const newShoppingList = await makeListChange({oldList: shoppingList, newValues: newContent, onError, config: appSettings, params: {suppressProductsReload: true}});          
          setShoppingList(newShoppingList);
        };
        evaluateChange();        
      }
    } else {
      setShoppingList(param);
    }
  };


  return [shoppingList, refreshFunction];
  */
}
