import { atom, atomFamily } from 'recoil';
import { getCachedSettings } from '../lib/settings';

export const listStateFamily = atomFamily({
  key: 'list',
  default: {},
});

export const testStateFamily = atomFamily({
  key: 'test',
  default: {"default": true},
});

export const userState = atom({
  key: 'user',
  default: null,
});

export const messageHistoryState = atom({
  key: 'messageHistory',
  default: [],
});

export const settingsState = atom({
  key: 'settings',
  default: getCachedSettings(),
});

export const settingsServerStoredState = atom({
  key: 'settingsServerStored',
  default: null,
});

export const serverListState = atom({
  key: 'serverList',
  default: null,
});

export const unitsState = atom({
  key: 'units',
  default: [],
});

export const shopsState = atom({
  key: 'shops',
  default: [],
});


export const tagsState = atom({
  key: 'tags',
  default: [],
});
