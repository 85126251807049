import React from 'react';
import AppRoutes from './AppRoutes';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { lightGreen } from '@mui/material/colors';
import ShopsProvider from './ui-elements/providers/ShopsProvider';
import EventSourceDataProvider from './ui-elements/providers/EventSourceDataProvider';
import ListProvider from './ui-elements/providers/ListProvider';
import useSettings from './hook/useSettings';
import { APP_VERSION } from './utility/const';
import ServerStoredSettingsProvider from './ui-elements/providers/ServerStoredSettingsProvider';
import SystemMessageHandler from './ui-elements/SystemMessageHandler';
import UnitProvider from './ui-elements/providers/UnitProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert } from '@mui/material';
import { IntlProvider } from 'react-intl';
import messagesDefinition from "./i18n/messages";
import { useRecoilValue } from 'recoil';
import { localeState } from './lib/localeState';

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFA000"
    },
    secondary: {
      main: "#F0F0F0"
    },    
    text: {
      primary: '#000000',
      secondary: '#0F0F0F',
    }
  },
  

});

function App() {
  const [settings, setSettings] = useSettings();
  const locale = useRecoilValue(localeState);
  React.useEffect(() => {
    if (settings?.appVersion) {
      if (settings.appVersion.value !== APP_VERSION) {
        setSettings(null); //reset settings
      }
    }
  }, [settings?.appVersion]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={
          <Alert severity="error" variant="filled">
            Uncatched error occured. Please try again later.
          </Alert>
        }
        onError={(error, errorInfo) =>
          console.error(`Error Boundary catched exception '${error}' with error info '${errorInfo}'`)
        }
      >
        <IntlProvider locale={locale} messages={messagesDefinition[locale]}>
          <UnitProvider />
          <ShopsProvider />
          <EventSourceDataProvider />
          <ServerStoredSettingsProvider />
          <ListProvider />
          <SystemMessageHandler />
          <AppRoutes />
        </IntlProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
