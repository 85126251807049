import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  Backdrop,
  CircularProgress,
  darken,
  Divider,
  lighten,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import RecipeCard from '../ui-elements/RecipeCard';
import SearchField from '../ui-elements/SearchField';
import TagSelector from '../ui-elements/TagSelector';
import { fetchApiGet, fetchApiPost } from '../utility/fetchApi';
import { addIngredients } from '../utility/listlib';
import useShoppingList from '../utility/useShoppingList';
import useUser from '../utility/useUser';
import useSettings from '../hook/useSettings';
import useShowMessage from '../hook/useShowMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import ListCard from '../ui-elements/ListCard';

const _ = require('lodash');

export default function ListsPage({ filterOptions, setFilterOptions }) {
  const [filter, setFilter] = React.useState('');
  const history = useHistory();
  const [showMessage, setShowMessage] = useShowMessage();
  const [userSettingsData, setUserSettingsData] = useUser({ loginRequired: true });
  const [settings, setSettings] = useSettings();
  const [settingsApplied, setAppSettingsDataApplied] = React.useState(false);

  const [shoppingList, setShoppingList] = useShoppingList();
  const [recipesExt, setRecipesExt] = React.useState([]);
  const [loadingExternal, setLoadingExternal] = useState(true);
  const [loadingInternal, setLoadingInternal] = useState(true);
  const [lists, setLists] = React.useState([]);
  const onFailed = (error) => {
    setShowMessage({ open: true, severity: 'error', message: error.message });
  };
  const [selectedTags, setSelectedTags] = React.useState([]);
  const intl = useIntl();

  // const [speedDialOpened, setSpeedDialOpened] = React.useState(false);
  // const handleSpeedDialOpen = () => setSpeedDialOpened(true);
  // const handleSpeedDialClose = () => setSpeedDialOpened(false);

  //trigger search
  useEffect(() => {
    try {
      setLoadingExternal(true);
      setLoadingInternal(true);
      setLists([]);
      setRecipesExt([]);
      const loadData = async (textToSearch) => {
        const loadFunction = async (apiMethodName, apiMethodParams, setMethod) => {
          const loaded = await fetchApiPost(apiMethodName, apiMethodParams);
          setMethod(loaded);
        };
        if (selectedTags.length > 0 && _.isObject(settings)) {
          const tagIds = (selectedTags ?? []).map((tag) => tag.tagId);
          loadFunction('list/advancedSearchWrapper', { name: textToSearch, tagIds }, (loaded) => {
            loaded = loaded.filter((item) => item.listId !== shoppingList.listId)            
            setLists(loaded);
            setLoadingInternal(false);
          }); //recipe
        } else if (selectedTags.length == 0) {
          loadFunction('list/findWrapper', { name: textToSearch }, (loaded) => {
            loaded = loaded.filter((item) => item.listId !== shoppingList.listId)
            setLists(loaded);
            setLoadingInternal(false);
          }); //recipe
        }
        loadFunction('recipe-ext/find', { name: textToSearch }, (loaded) => {
          setRecipesExt(loaded);
          setLoadingExternal(false);
        }); //recipe-ext
      };
      if (_.isObject(settings)) {
        const textToSearch = filter.length == 0 ? settings?.listSearchHistoryId?.value : filter;
        setSettings({ ...settings, listFilter: { scope: 'local', value: { filter: textToSearch, selectedTags } } });
        console.log(
          `Loading data with test to search '${textToSearch}' and tags '${JSON.stringify(
            selectedTags
          )} and appData ${JSON.stringify(settings)}`
        );
        loadData(textToSearch);
      }
    } catch (error) {
      onFailed(error);
    }
  }, [selectedTags, filter.length === 0 ? settings?.listSearchHistoryId?.value ?? '' : filter, _.isObject(settings)]);

  useEffect(() => {
    if (!settingsApplied && _.isObject(settings) && settings?.listFilter?.value) {
      //apply once during load
      setAppSettingsDataApplied(true);
      const savedFilter = settings.listFilter?.value;
      setSelectedTags(savedFilter.selectedTags);
    }
  }, [_.isObject(settings)]);

  /* const actions = [
    { icon: <ShoppingCartIcon />, name: 'Cart', action: () => history.push(`/lists/newShoppingList`) },
    { icon: <FormatListBulletedIcon />, name: 'List', action: () => history.push(`/lists/newList`) },
  ];*/

  const handleAddToCart = async (listId) => {
    try {
      setShowMessage({
        open: true,
        severity: 'info',
        message: intl.formatMessage({
          id: 'lists.addIngredientsIntoShoppingList.start',
          defaultMessage: `Adding ingredients into shopping list.`,
        }),
      });
      const data = await fetchApiPost('list/getWrapper', { listId });
      if (_.isArray(data) && data.length == 1) {
        const result = await addIngredients({
          list: shoppingList,
          newValues: data[0].content,
          config: settings,
        });
        setShoppingList(result);
        setShowMessage({
          open: true,
          severity: 'success',
          message: intl.formatMessage({
            id: 'lists.addIngredientsIntoShoppingList.finished',
            defaultMessage: `Ingredients added into shopping list.`,
          }),
        });
      } else {
        setShowMessage({
          open: true,
          severity: 'error',
          message: intl.formatMessage({
            id: 'lists.addIngredientsIntoShoppingList.failed',
            defaultMessage: 'Unable to load selected list.',
          }),
        });
      }
    } catch (error) {
      setShowMessage({ open: true, severity: 'error', message: error.message });
    }
  };

  const handleRunFilter = (filter) => {
    setFilter(filter);
  };

  return (
    <AppLayout
      title={intl.formatMessage({ id: 'list.searchList', defaultMessage: 'Search list' })}
      mainIconMeaning="menu"
    >
      <SearchField onRunFilter={handleRunFilter} historyId="listSearchHistoryId" />
      {/*  <TagSelector selectedTags={selectedTags} setSelectedTags={setSelectedTags} /> */}

      {(lists.length > 0 || loadingInternal) && (
        <Box>
          <Typography variant="h6" m={2}>
            <FormattedMessage id="list.myLists" defaultMessage="My lists" />
          </Typography>
          {loadingInternal ? <CircularProgress sx={{ padding: '16px' }} /> : <Divider variant="middle" />}

          <List
            key="myLists"
            sx={{
              display: 'grid',
              gridGap: '10px',
              gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', //grid automagic
              justifyContent: 'space-around',
              padding: '15px',
            }}
            component="nav"
          >
            {lists.map((list) => (
              <ListCard
                user={list.user}
                key={list.listId}
                title={list.name}
                updatedOn={list.updatedOn}
                onError
                content={list.content}
                onClick={() => history.push(`/lists/${list.listId}`)}
                addToCartAction={() => handleAddToCart(list.listId)}
              />
            ))}
          </List>
        </Box>
      )}      

      {((_.isArray(recipesExt) && recipesExt.length > 0) || loadingExternal) && (
        <Box>
          <Typography variant="h6" m={2}>
            <FormattedMessage id="list.internetSearch" defaultMessage="Internet search" />
          </Typography>
          {loadingExternal ? <CircularProgress sx={{ padding: '16px' }} /> : <Divider variant="middle" />}
        </Box>
      )}
      <List
        key="externalRecipes"
        sx={{
          display: 'grid',
          gridGap: '10px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))', //grid automagic
          justifyContent: 'space-around',
          padding: '15px',
        }}
        component="nav"
      >
        {(_.isArray(recipesExt) && recipesExt.length > 0 ? recipesExt : []).map((product) => (
          <RecipeCard
            key={product.sourceUrl}
            title={product.name}
            imagePath={product.img}
            onClick={() => history.push(`/lists/new/${encodeURIComponent(product.sourceUrl)}`)}            
            externalUrl={product.sourceUrl}
          />
        ))}
      </List>
      <Box sx={{ position: 'relative', float: 'right' }}>
        <Box sx={{ position: 'absolute', right: '5em' }}>
          <Fab
            color="secondary"
            aria-label="Add"
            sx={{ position: 'fixed', bottom: '5rem' }}
            onClick={() => history.push('/lists/new')}
          >
            <AddIcon />
          </Fab>
        </Box>
      </Box>
      {/* <Box sx={{ position: 'relative', float: 'right' }}>
        <Box sx={{ position: 'absolute', right: '5em' }}>
          <Box sx={{ transform: 'translateZ(0px)', position: 'fixed', bottom: '5rem' }}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              icon={<SpeedDialIcon />}
              onClose={handleSpeedDialClose}
              onOpen={handleSpeedDialOpen}
              open={speedDialOpened}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={action.action}
                />
              ))}
            </SpeedDial>
          </Box>
        </Box> 
      </Box>*/}
    </AppLayout>
  );
}
