import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GoogleLogin, googleLogout, GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect } from 'react';
import { fetchApiPost } from '../utility/fetchApi';
//https://reactjsexample.com/google-oauth2-using-the-new-google-identity-services-sdk-for-react/
//https://github.com/MomenSherif/react-oauth
//https://react-oauth.vercel.app/
//https://www.youtube.com/watch?v=75aTZq-qoZk&ab_channel=CodingwithBasir
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import AppSettingsForm from '../ui-elements/ShopConfigurationForm';
import useUser from '../utility/useUser';
import { Box } from '@mui/material';
import ShopConfigurationForm from '../ui-elements/ShopConfigurationForm';
import UserPreferenceForm from '../ui-elements/UserPreferenceForm';
import { FormattedMessage, useIntl} from 'react-intl';
import { ZoomOutMapTwoTone } from '@mui/icons-material';
import { DefaultValue } from 'recoil';
const clientId = '744242036623-6d3pb67qmk6e1nncam780m54io5q0vcg.apps.googleusercontent.com';

export default function UserSettingsPage() {
  const [user, setUser] = useUser({ loginRequired: true });
  let { section } = useParams();
  const intl = useIntl();
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    if(isExpanded){
      setExpanded(panel);
    }else{
      setExpanded(panel === 'userSettingsPanel' ? 'shopConfigPanel' : 'userSettingsPanel');  
    }    
  };

  useEffect(() => {
    if(section === `shops` || section === undefined){
      setExpanded(`shopConfigPanel`);
    }else{
      setExpanded(`userSettingsPanel`);
    }
  }, [section])

  
  

  //https://mui.com/system/grid/
  return (
    <AppLayout title={intl.formatMessage({id:'userSettings.userSettings', defaultMessage: 'User settings'})} mainIconMeaning="back" width>
      <Box sx={{margin: '15px'}}>
      <Accordion  expanded={(expanded ?? 'userSettingsPanel') === 'userSettingsPanel'} onChange={handleChange('userSettingsPanel')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography sx={{ width: '95%', flexShrink: 0 }}>
            <FormattedMessage id='userSettings.YumDroidPreferences' defaultMessage='YumDroid assistent preferences'/>
            &nbsp;<FormattedMessage id='userSettings.experimental' defaultMessage='(experimental)'/>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserPreferenceForm/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={(expanded ?? 'shopConfigPanel') === 'shopConfigPanel'} onChange={handleChange('shopConfigPanel')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography sx={{ width: '95%', flexShrink: 0 }}>
          <FormattedMessage id='userSettings.shopConfiguration' defaultMessage='Shop configuration'/></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ShopConfigurationForm />
        </AccordionDetails>
      </Accordion>
      </Box>
    </AppLayout>
  );
}
