import _ from 'lodash';
import { useEffect, useState } from 'react';
import useSettings from '../hook/useSettings';
import useShops from './useShops';

export default function usePrefferedShop({ onError }) {
  const [settings, setSettings] = useSettings();
  const [prefferedShop, setPrefferedShop] = useState(undefined);
  const shops = useShops({onError});
  useEffect(() => {
    if(_.isObject(settings)){
      if(settings?.prefferedShopId?.value){
        const prefferedShopId = settings?.prefferedShopId?.value
        const selectedShop = shops.find(item => item.shopId === prefferedShopId)
        setPrefferedShop(selectedShop);      
      }else{
        setPrefferedShop(undefined)
      }            
    }
  }, [settings?.prefferedShopId?.value, (shops?.length ?? 0) > 0])

  return [prefferedShop, setPrefferedShop];
}
