import _ from 'lodash';
import React from 'react';
// import Autosuggest from 'react-autosuggest';
import Autocomplete from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import useFetchApi from '../utility/useFetchApi';
// import useT from '../utility/useT';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

const extractValue = (valueField) => (obj) => {
  if (!valueField) return obj;
  return _.isFunction(valueField) ? valueField(obj) : _.get(obj, valueField);
};

const renderSuggestion =
  (primaryField, secondaryField, classes) =>
  (suggestion, { query, isHighlighted }) => {
    const primaryLabel = extractValue(primaryField)(suggestion);
    const secondaryLabel = extractValue(secondaryField)(suggestion);

    return (
      <ListItem selected={isHighlighted} component="div">
        <ListItemText primary={primaryLabel} secondary={secondaryLabel} />
      </ListItem>
    );
  };

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  inputLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
}));

function AutocompleteField({
  label,
  url,
  onSelect,
  getUrlParams,
  // onChangeRaw,
  shouldRenderSuggestions,
  primaryField,
  secondaryField,
  valueField,
  textField,
  placeholder,
  value,
  setValue,
  onError,
}) {
  const classes = useStyles();
  const [containsFilter, setContainsFilter] = React.useState(null);
  const setDebouncedContainsFilter = React.useRef(_.debounce((value) => setContainsFilter(value), 300));

  // const urlContains = containsFilter == null ? null : url(containsFilter);
  const data = useFetchApi(containsFilter ? url : null, getUrlParams(containsFilter), [], null, onError);
  //console.log('data' + JSON.stringify(data));

  const customItem = {    
    categoryName: 'NoCategory',
    estimatedAmountOfProducts: 1,
    unit: [13, 4, 1],
    recipeIngredientId: null,
    probability: 1,
    name: !_.isNil(containsFilter) ? containsFilter : '',
  };

  let extendedData;
  if (_.isArray(data)) {
    if (
      //check wheter to add custom item: is not already present, filter is not empty and the same item was not already found
      data.filter((item) => (item.recipeIngredientId == null ? true : false)).length == 0 &&
      !_.isNil(containsFilter) &&
      data.filter((item) => item.name == customItem.name).length == 0
    ) {
      data.push(customItem);
    } else {
      if (_.isNil(containsFilter)) {
        extendedData = data.map((item) => (item.recipeIngredientId == null ? null : item));
      } else {
        extendedData = data.map((item) => (item.recipeIngredientId == null ? customItem : item));
      }
    }
  }
  //console.log('VALUE', value);
  //console.log('DATA', extendedData || data || []);
  return (
    <Autocomplete
      // disablePortal
      id="ingredient-autocomplete"
      label={label}
      sx={{ m: 2 }}
      value={value ?? ""}
      isOptionEqualToValue={(option, value) => option.name == value}
      options={(extendedData || data || []).map((x) => ({ ...x, label: x.name }))}
      // sx={{ width: 300 }}
      renderInput={(params) => /*{console.log('params', params);*/ {return <TextField {...params} label={label} />}}
      onInputChange={(ev, val) => {
        //console.log('INPUT', val);
        setDebouncedContainsFilter.current(val);
      }}
      onChange={(ev, val, reason) => {
        // console.log('CHANGE', val, reason);
        setValue(val);
      }}
    />
  );

  // return (
  //   <FormGroup>
  //     <InputLabel className={classes.inputLabel}>{label}</InputLabel>

  //     {/* <Autosuggest
  //       renderInputComponent={renderInputComponent}
  //       suggestions={data}
  //       onSuggestionsFetchRequested={setDebouncedContainsFilter.current}
  //       onSuggestionsClearRequested={() => setContainsFilter(null)}
  //       getSuggestionValue={extractValue(valueField)}
  //       renderSuggestion={renderSuggestion(primaryField, secondaryField, classes)}
  //       onSuggestionSelected={(ev, value) => {
  //         if (onSelect) onSelect(value.suggestion);
  //       }}
  //       inputProps={{
  //         classes,
  //         placeholder,
  //         value: (_.isString(value) || value == null ? value : extractValue(textField)(value)) || '',
  //         onChange: (ev, { newValue }) => setValue(newValue),
  //       }}
  //       shouldRenderSuggestions={(value) => {
  //         if (shouldRenderSuggestions) return shouldRenderSuggestions(value);
  //         return (value || '').length >= 1;
  //       }}
  //       theme={{
  //         container: classes.container,
  //         suggestionsContainerOpen: classes.suggestionsContainerOpen,
  //         suggestionsList: classes.suggestionsList,
  //         suggestion: classes.suggestion,
  //       }}
  //       renderSuggestionsContainer={(options) => (
  //         <Paper {...options.containerProps} square>
  //           {options.children}
  //         </Paper>
  //       )}
  //     /> */}
  //   </FormGroup>
  // );
}

export default AutocompleteField;
