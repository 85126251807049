import { googleLogout } from '@react-oauth/google';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import useShowMessage from '../hook/useShowMessage';
import useUser from '../utility/useUser';
import { fetchApiPost } from '../utility/fetchApi';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export default function LogoutPage() {
  const [user, setUser] = useUser({ loginRequired: false });
  const history = useHistory();

  const logout = async () => {
    fetchApiPost('user/logout', { id: user.bearer })
      .then((result) => {
        localStorage.removeItem('user');
        setUser({ loginStatus: 'loggedOut' });
        googleLogout();
      })
      .catch((error) => {
        localStorage.removeItem('user');
        setUser({ loginStatus: 'loggedOut' });
        googleLogout();
      });
  };

  useEffect(() => {
    if (user.loginStatus === 'loggedIn') {
      logout();
    }
  }, [user.loginStatus]);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        minHeight: 'calc(100vh)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: 'calc(-180px + 100vh)',
          backgroundColor: 'white',
        }}
      >
        <img src={'../../images/YumDroid.png'} width="256px" alt={`YouDroid`} />
        {user.loginStatus !== 'loggedOut' ? (
          <Box>
            <Typography>
              <FormattedMessage id="logout.running" defaultMessage="Logging out" />
            </Typography>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography margin={2} sx={{ marginBottom: '60px' }}>
              <FormattedMessage
                id="logout.finished"
                defaultMessage="You were successfuly logged out. I am looking to see you again!!!"
              />
            </Typography>
            <div align={'center'} margin={2}>
              <Link href="login">
                <FormattedMessage id="logout.login" defaultMessage="Login" />
              </Link>
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
}
