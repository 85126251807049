import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Grid,
  ListItemButton,
  Typography,
  colors,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import BottomSheetMenu from '../ui-elements/BottomSheetMenu';
import IngredientsList from '../ui-elements/IngredientsList';
import ProductImage from '../ui-elements/ProductImage';
import SuggestedProducts from '../ui-elements/SuggestedProducts';
import useFetchApi from '../utility/useFetchApi';
import useShoppingList from '../utility/useShoppingList';
import useUser from '../utility/useUser';
import _ from 'lodash';
import useSettings from '../hook/useSettings';
import { useIntl, FormattedMessage } from 'react-intl';
import useShowMessage from '../hook/useShowMessage';
import { fetchApiPost } from '../utility/fetchApi';
import useShops from '../utility/useShops';
import { ShopConnectStatus } from '../lib/shopsLib.ts';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { createEmptyList } from '../utility/listlib';
import ProductLike from '../ui-elements/ProductLike';

export default function ShopDetailPage() {
  const intl = useIntl();
  const [userSettingsData, setUserSettingsData] = useUser({ loginRequired: true });
  const [openConfirmNotMappedDialog, setOpenConfirmNotMappedDialog] = React.useState(false);
  const [openArchiveListDialog, setOpenArchiveListDialog] = React.useState(false);
  let { shopId } = useParams();
  const [settings, setSettings] = useSettings();
  const onError = (error) => {
    console.log(JSON.stringify(error));
  };
  const shops = useShops({ onError });
  const [currentShop, setCurrentShop] = useState();
  useEffect(() => {
    const currentShop = shops?.find((item) => item.name === shopId);
    setCurrentShop(currentShop);
  }, [shops, shopId]);
  const [showMessage, setShowMessage] = useShowMessage();
  const [goToShopInProgress, setGoToShopInProgress] = useState(false);
  const unitList = useFetchApi('unit/find', { type: 'basic' });
  const [amount, setAmount] = useState(1);
  const [shoppingList, setShoppingList] = useShoppingList(onError);
  //const { shopId } = //match.params;
  const history = useHistory();
  const notMappedIngredients = (shoppingList?.content || []).filter((x) => !x.hasOwnProperty(shopId));

  const mappedIngredients = (shoppingList?.content || []).filter((x) => x.hasOwnProperty(shopId));

  const [productMenu, setProductMenu] = React.useState(null);

  const modifyProductsAmount = (newAmount) => {
    if (newAmount < 0) return;
    const modifyAmount = (content) => {
      const rowIndex = content.findIndex((item) => item.rowId === productMenu.rowId);
      const row = { ...content[rowIndex], version: content[rowIndex]?.version + 1 };
      row[shopId] = { ...row[shopId], amount: newAmount };
      const newContent = content.map((item) => (item.rowId === productMenu.rowId ? row : item));
      return newContent;
    };
    setShoppingList({
      action: 'modifyContent',
      context: (content) => modifyAmount(content),
      params: { suppressProductsReload: true },
    });
    setAmount(newAmount);
  };

  const archiveShoppingList = async() => {
    const newList = {...shoppingList, name: `Shopping list (${(new Date()).toLocaleDateString("cz-CS")})`};
    setShoppingList({ context: newList, action: 'modifyContent' });
    const newEmptyList = await createEmptyList({name: `Shopping list`})
    setShoppingList(newEmptyList);
    history.push('/lists');
  }

  const triggerGoToShop = async () => {
    if (notMappedIngredients.length > 0) {
      setOpenConfirmNotMappedDialog(true);
    } else {
      makeShopping();
    }
  };

  const makeShopping = async () => {
    setGoToShopInProgress(true);
    const shop = currentShop;
    setShowMessage({
      open: true,
      severity: 'info',
      message: intl.formatMessage({
        id: 'shopDetail.insertIntoShop.start',
        defaultMessage: 'Inserting your shopping list into target grocery shop.',
      }),
    });

    const products = shoppingList?.content?.map((element) => {
      if (!element[shopId] && element[shopId]?.productId) {
        return null;
      } else {
        return {
          productId: element[shopId]?.productId,
          productUrl: element[shopId]?.productUrl,
          name: element['name'],
          amount: Number(element[shopId]?.amount) ?? 1,
        };
      }
    });
    try {
      const result = await fetchApiPost('shopConnector/synchronizeShoppingListWrapper', {
        shopId: shop.shopId,
        products,
      });
      setShowMessage({
        open: true,
        severity: 'info',
        message: intl.formatMessage({
          id: 'shopDetail.insertIntoShop.end',
          defaultMessage: 'Shopping list synchronized.',
        }),
      });
      window.open(shop.shoppingCartUrl, '_blank');
      setOpenArchiveListDialog(true);
    } catch (error) {
      setShowMessage({
        open: true,
        severity: 'error',
        message:
          intl.formatMessage({
            id: 'shopDetail.insertIntoShop.failed',
            defaultMessage: 'Shopping list synchronization failed with following error',
          }) +
          ': ' +
          error?.message,
      });
    } finally {
      setGoToShopInProgress(false);
    }
  };
  const getUnitNameByID = (id) => {
    const result = unitList.find((element) => element.unitId == id);
    if (result) return result['name'];
  };

  return (
    <AppLayout
      title={shopId}
      mainIconMeaning="back"
      /*customButtons={
        <Box sx={{ whiteSpace: 'nowrap' }}>
          
        </Box>
      }*/
    >
      {currentShop?.shopConnectStatus === ShopConnectStatus.NotConfigured && (
        <Button
          onClick={() => {
            history.push(`/userSettings/shops`);
          }}
          variant="contained"
          sx={{ position: 'relative', float: 'right', margin: '15px' }}
        >
          <FormattedMessage id="shopDetail.configureShop" defaultMessage="Configure shop" />
        </Button>
      )}
      {currentShop?.shopConnectStatus === ShopConnectStatus.Configured && (
        <Button
          onClick={() => triggerGoToShop()}
          variant="contained"
          disabled={goToShopInProgress}
          sx={{ position: 'relative', float: 'right', margin: '15px' }}
        >
          {goToShopInProgress && <CircularProgress size="1rem" sx={{ height: '16px', marginRight: '16px' }} />}
          <FormattedMessage id="shopDetail.goToShop" defaultMessage="Go to shop" />
        </Button>
      )}
      {currentShop?.shopConnectStatus === ShopConnectStatus.NotSupported && (
        <Box sx={{ position: 'relative', float: 'right', margin: '15px' }}>
          <FormattedMessage id="shopDetail.notSupported" defaultMessage="Connect is not supported" />
        </Box>
      )}

      {notMappedIngredients.length >= 0 && (
        <>
          <Typography variant="h4" m={2}>
            <FormattedMessage id="shopDetail.ingredientsToMap" defaultMessage="Ingredients to map" /> (
            {notMappedIngredients.length})
          </Typography>

          <IngredientsList
            data={shoppingList?.content}
            displayFilter={(item) => !item[shopId]}
            setData={(content) => {
              setShoppingList({ action: 'modifyContent', context: content });
            }}
            unitList={unitList}
            onError={onError}
            menuItems={(item) => [
              {
                icon: EditIcon,
                text: 'Find product for this ingredient',
                onClick: () =>
                  history.push(
                    `/find-product-for-ingredient/${encodeURIComponent(shopId)}/${encodeURIComponent(item.rowId)}`
                  ),
              },
            ]}
          />
        </>
      )}
      <Dialog
        open={openConfirmNotMappedDialog}
        onClose={() => setOpenConfirmNotMappedDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Not mapped ingredients'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There are ({notMappedIngredients.length}) ingredients without product. Those ingredients will not be
            transffered into shop. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmNotMappedDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpenConfirmNotMappedDialog(false);
              makeShopping();              
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openArchiveListDialog}
        onClose={() => setOpenArchiveListDialog(false)}
        aria-labelledby="archive-dialog-title"
        aria-describedby="archive-dialog-description"
      >
        <DialogTitle id="archive-dialog-title">{'Archive shopping list'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="archive-dialog-description">
            Do you want to archive current shopping list and start with new one?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenArchiveListDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpenArchiveListDialog(false);
              archiveShoppingList();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h4" m={2}>
        <FormattedMessage id="shopDetail.productsToCheck" defaultMessage="Products to check" /> (
        {mappedIngredients.length})
      </Typography>
      <List>
        {mappedIngredients.map((ingredient) => (
          <ListItemButton
            key={(Math.random() + 1).toString(36).substring(7)}
            onClick={() => {
              setAmount(ingredient[shopId].amount);
              setProductMenu(ingredient);
            }}
          >
            <Box textAlign="right">
              <Typography variant="h6">{ingredient[shopId].amount}x &nbsp;</Typography>
            </Box>
            <ProductImage maxWidth={60} maxHeight={60} product={ingredient[shopId]} />
            <ListItemText
              primary={
                <Grid container justifyContent={'space-between'} sx={{ flexWrap: 'nowrap' }}>
                  <Typography variant="body2">
                    {ingredient[shopId].name}, {ingredient[shopId]['productQuantity.value']}{' '}
                    {ingredient[shopId]['productQuantity.unit']}
                  </Typography>
                  <Typography variant="body1">
                    {((ingredient[shopId].amount ?? 1) * ingredient[shopId].price).toFixed(2)},-
                  </Typography>
                </Grid>
              }
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                    <FormattedMessage id="shopDetail.sourceIngredient" defaultMessage="Source ingredient" />
                    {': '}
                    {`${ingredient.amount ?? ''} ${getUnitNameByID(ingredient.unitId) ?? ''} ${ingredient.name}`}
                  </Typography>
                  {ingredient[shopId].resultMessage != null && ` - ${ingredient[shopId]?.resultMessage}`}
                </React.Fragment>
              }
            />
          </ListItemButton>
        ))}
      </List>
      <BottomSheetMenu
        open={!!productMenu}
        /*title={productMenu?.name}*/
        onClose={() => setProductMenu(null)}
        topContent={
          <Box>
            <Box container sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ padding: 1, paddingTop: 3, position: 'relative' }}>                
                <ProductImage product={_.get(productMenu, shopId)} maxWidth={200} maxHeight={200} />
                <Box sx={{ position: 'absolute', top: '5px', right: '10px' }}>
                    <ProductLike
                      productId={_.get(productMenu, [shopId, 'productId'])}
                      productUrl={_.get(productMenu, [shopId, 'productUrl'])}
                      isLiked={_.get(productMenu, [shopId, 'isLiked'])}
                      shopId={shopId}
                      userId={_.get(productMenu, [shopId, 'userId'])}
                    />
                  </Box>  
                  <Box sx={{ position: 'absolute', top: '5px', left: '10px' }}>
                    <img src='/icon.png' width='16px'></img>&nbsp;
                    {_.get(productMenu, [shopId, 'customScore'])?.toFixed(1)}
                  </Box>  
              </Box>
              <Box sx={{ padding: 5, paddingLeft: 0 }}>
                <Link to={{ pathname: _.get(productMenu, [shopId, 'productUrl']) }} target="_blank" rel="noreferrer">
                  <Typography variant="body1">{_.get(productMenu, [shopId, 'name'])}</Typography>
                </Link>
                <Typography variant="body1">
                  {intl.formatMessage(
                    { id: 'shopDetail.packageSize', defaultMessage: 'Package size: {packageSize}' },
                    { packageSize: _.get(productMenu, [shopId, 'productQuantity.value']) }
                  )}
                  {_.get(productMenu, [shopId, 'productQuantity.unit'])}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage(
                    { id: 'shopDetail.unitPrice', defaultMessage: 'Unit price: {price},-' },
                    { price: _.get(productMenu, [shopId, 'price'])?.toFixed(2) }
                  )}
                </Typography>
                <Box display={'flex'} flexDirection={'row'}>
                  <IconButton
                    sx={{ m: 1, margin: '4px' }}
                    onClick={() => {
                      modifyProductsAmount(Number(amount) + 1);
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                  <Input
                    value={amount}
                    size="medium"
                    sx={{ px: 1, py: 1, fontSize: 'x-large', maxWidth: '3em' }}
                    onChange={(ev) => {
                      setAmount(ev.target.value);
                    }}
                    onBlur={(ev) => {
                      modifyProductsAmount(ev.target.value);
                    }}
                    textAlign="center"
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 1000,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                      style: { textAlign: 'center' },
                    }}
                  />
                  <IconButton
                    sx={{ m: 1 }}
                    onClick={() => {
                      modifyProductsAmount(Number(amount) - 1);
                    }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Box>
                <Typography variant="h6">{(amount * _.get(productMenu, [shopId, 'price']))?.toFixed(2)},-</Typography>
              </Box>
            </Box>
            <SuggestedProducts
              shopId={shopId}
              ingredient={productMenu}
              userId={userSettingsData?.userId}
              originalProductUrl={_.get(productMenu, [shopId, 'productUrl'])}
              onClose={() => {
                //setShoppingList({...shoppingList, content});
                setProductMenu(null);
              }}
            />
          </Box>
        }
        items={[
          {
            icon: EditIcon,
            text: intl.formatMessage({ id: 'shopDetail.findAnotherProduct', defaultMessage: 'Find another product' }),
            onClick: () => {
              history.push(
                `/find-product-for-ingredient/${encodeURIComponent(shopId)}/${encodeURIComponent(productMenu.rowId)}`
              );
            },
          },
          {
            icon: DeleteIcon,
            text: intl.formatMessage({ id: 'shopDetail.removeProduct', defaultMessage: 'Remove product' }),
            //todo            new ShoppingList(data).removeIngredient(productMenu, onError)
            onClick: () => {
              setShoppingList({
                action: 'modifyContent',
                context: (content) => content.filter((item) => item.rowId !== productMenu.rowId),
              });
            },
          },
          {
            icon: CloseIcon,
            text: intl.formatMessage({ id: 'shopDetail.close', defaultMessage: 'Close' }),
            onClick: () => setProductMenu(null),
          },
        ]}
      />
    </AppLayout>
  );
}
