import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import useFetchApi from '../utility/useFetchApi';
import useShoppingList from '../utility/useShoppingList';
import useUser from '../utility/useUser';
import useShops from '../utility/useShops';
import { resetProducts } from '../utility/listlib';
import { Button, Divider } from '@mui/material';
import useShowMessage from '../hook/useShowMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import useSettings from '../hook/useSettings';
import { getShopNameFromUrl } from '../utility/shops';

function Shops(props) {
  const { shops } = props;
  const history = useHistory();
  const [settings, setSettings] = useSettings();
  return (
    <List
      sx={{
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', //grid automagic
        justifyContent: 'space-around',
        padding: '15px',
      }}
      component="nav"
    >
      {shops.map((shop) => (
        <ListItem key={shop.shopId} button onClick={() => history.push(`/shops/${shop.name}`)}>
          <ListItemText
            sx={{ textAlign: 'center' }}
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  justifyContent={'center'}
                  textAlign={'center'}
                >
                  <img src={shop.logoPath} width="70%" />
                </Typography>
              </React.Fragment>
            }

            /* secondary={
            <React.Fragment>
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                {shop}
              </Typography>
          </React.Fragment>} */
          />
        </ListItem>
      ))}
    </List>
  );
}

export default function ShopsPage({ shoppingListState }) {
  const [userSettingsData, setUserSettingsData] = useUser({ loginRequired: true });
  const [showMessage, setShowMessage] = useShowMessage();
  const intl = useIntl();

  const onFailed = (error) => {
    console.log(JSON.stringify(error));

    setShowMessage({ open: true, severity: 'error', message: error.message });
  };
  const [data, setData] = useState([]);
  const [shoppingList, setShoppingList] = useShoppingList(shoppingListState, onFailed);
  React.useEffect(() => {
    setData(shoppingList?.content);
  }, [shoppingList]);
  const shops = useShops({ onError: onFailed });
  const history = useHistory();

  const shopsDataExtended = {};
  if (data?.length > 0 && shops?.length > 0) {
    data.forEach((ingredient) => {
      shops.forEach((shop) => {
        const shopKey = shop.name;
        if (!shopsDataExtended[shopKey]) {
          shopsDataExtended[shopKey] = {};
          shopsDataExtended[shopKey]['connectSupported'] = shops?.find((item) => item.name === shopKey)?.[
            'connectSupported'
          ];
        }
        if (ingredient.hasOwnProperty(shopKey)) {
          const product = ingredient[shopKey];
          shopsDataExtended[shopKey]['totalPrice'] =
            Number(shopsDataExtended[shopKey]?.['totalPrice'] ?? 0) + Number(product.price * product.amount);
          shopsDataExtended[shopKey]['matched'] = !shopsDataExtended[shopKey]['matched']
            ? 1
            : shopsDataExtended[shopKey]['matched'] + 1;
        } else {
          shopsDataExtended[shopKey]['missing'] = !shopsDataExtended[shopKey]['missing']
            ? 1
            : shopsDataExtended[shopKey]['missing'] + 1;
        }
      });
    });
  }
  const shopsDataConnectSupportedSorted = [];
  for (var shopId in shopsDataExtended) {
    if (shopsDataExtended[shopId]['connectSupported']) {
      shopsDataConnectSupportedSorted.push([
        shopId,
        shopsDataExtended[shopId]['totalPrice'],
        shopsDataExtended[shopId]['matched'],
      ]);
    }
  }
  shopsDataConnectSupportedSorted.sort((a, b) => a[1] - b[1]);

  const shopsDataConnectNotSupportedSorted = [];
  for (var shopId in shopsDataExtended) {
    if (!shopsDataExtended[shopId]['connectSupported']) {
      shopsDataConnectNotSupportedSorted.push([
        shopId,
        shopsDataExtended[shopId]['totalPrice'],
        shopsDataExtended[shopId]['matched'],
      ]);
    }
  }
  shopsDataConnectNotSupportedSorted.sort((a, b) => a[1] - b[1]);

  const getShopById = (shopId) => {
    return shops.find((shop) => shop.name === shopId);
  };

  const refreshProductsHandler = async () => {
    setShowMessage({ open: true, severity: 'info', message: `Refreshing products in cart.` });
    setData((await resetProducts(shoppingList, onFailed, userSettingsData))?.content);
    setShowMessage({ open: true, severity: 'success', message: `Products refreshed.` });
  };

  return (
    <AppLayout title={intl.formatMessage({ id: 'shops.shopSelection', defaultMessage: 'Shop selection' })}>
      {Object.keys(shopsDataExtended).length == 0 && (
        <div>
          {data?.length == 0 && (
            <Typography m={2}>
              <FormattedMessage
                id="shops.noIngredients"
                defaultMessage="There are no ingredients in your shopping cart. You can start shopping directly in any of following shops:"
              />
            </Typography>
          )}
          {data?.length > 0 && (
            <Typography m={2}>
              {intl.formatMessage(
                {
                  id: 'shops.noProductsForIngredients',
                  defaultMessage:
                    'No relevant products were found for ({amount}) ingredients in your shopping list. You can find your products directly in any of following shops:',
                },
                { amount: data?.length }
              )}
            </Typography>
          )}
          <Shops shops={shops} />
        </div>
      )}
      {Object.keys(shopsDataExtended).length > 0 && (
        <div>
          <Box sx={{ textAlign: 'right', with: '100%' }}>
            <Button
              onClick={() => refreshProductsHandler()}
              variant="contained"
              sx={{ marginRight: '15px', marginTop: '15px' }}
            >
              <FormattedMessage id="shops.refreshProducts" defaultMessage="Refresh products" />
            </Button>
          </Box>
          <div>
            <List>
              {shopsDataConnectSupportedSorted.map((item) => (
                <ListItem key={item[0]} button onClick={() => history.push(`/shops/${item[0]}`)}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ width: '100%' }}
                  >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      {item[0] === 'kosik.cz' ? (
                        <img src={getShopById(item[0]).logoPath} height="30px" />
                      ) : (
                        <img src={getShopById(item[0]).logoPath} height="60px" />
                      )}
                      <Box m={1}>
                        <Typography>
                          (
                          {intl.formatMessage(
                            { id: 'shops.matchedProductsInfo', defaultMessage: 'Found {matched} of {all}' },
                            { matched: item[2], all: data.length }
                          )}
                          )
                        </Typography>
                        <Typography></Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography sx={{ display: 'inline' }} component="span" variant="h6" color="text.primary">
                        {`${item[1].toFixed(2)},- `}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>          
            <Typography variant='h6' m={2}>*** Online shopping not supported yet</Typography>
            <List>
              {shopsDataConnectNotSupportedSorted.map((item) => (
                <ListItem key={item[0]} button onClick={() => history.push(`/shops/${item[0]}`)}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ width: '100%' }}
                  >
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      {item[0] === 'kosik.cz' ? (
                        <img src={getShopById(item[0]).logoPath} height="30px" />
                      ) : (
                        <img src={getShopById(item[0]).logoPath} height="60px" />
                      )}
                      <Box m={1}>
                        <Typography>
                          (
                          {intl.formatMessage(
                            { id: 'shops.matchedProductsInfo', defaultMessage: 'Found {matched} of {all}' },
                            { matched: item[2], all: data.length }
                          )}
                          )
                        </Typography>
                        <Typography></Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography sx={{ display: 'inline' }} component="span" variant="h6" color="text.primary">
                        {`${item[1].toFixed(2)},- `}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>

            {/* <List>
              {shopsDataExtendedUnconnectable.map((item) => (
                <ListItem key={getShopNameFromUrl(item.productUrl)} button onClick={() => history.push(`/shops/${getShopNameFromUrl(item?.productUrl)}`)}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <img src={getShopById(getShopNameFromUrl(item?.productUrl)).logoPath} height="60px" />                      
                      <Box m={1}>
                        <Typography>
                        ({intl.formatMessage(
                          { id: 'shops.matchedProductsInfo', defaultMessage: 'Found {matched} of {all}' },
                          { matched: item.length, all: data.length }
                        )})
                        </Typography>                        
                      </Box>
                    </Box>
                    <Box>
                      <Typography sx={{ display: 'inline' }} component="span" variant="h6" color="text.primary">
                        {`${_.sumBy(item, (ingr) => ingr.price * ingr.amount).toFixed(2)},- `}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>   */}
          </div>
        </div>
      )}
    </AppLayout>
  );
}
