import useWindowSize from './useWindowSize';

export const drawerWidth = 0;

export default function useViewportSize() {
  const size = { ...useWindowSize() };
  /*if (size.width >= 600) {
    size.width -= drawerWidth;
    size.width -= 20;
  }*/
  size.height -= 120;
  return size;
}
