import TextareaAutosize from '@mui/base/TextareaAutosize';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';
import MoreIcon from '@mui/icons-material/MoreVert';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, Checkbox, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import SystemMessageHandler from '../ui-elements/SystemMessageHandler';
import IngredientsList from '../ui-elements/IngredientsList';
import TagSelector from '../ui-elements/TagSelector';
import { fetchApiPost } from '../utility/fetchApi';
import { removeList, addIngredients, resetProducts } from '../utility/listlib';
import useList from '../utility/useList';
import useShoppingList from '../utility/useShoppingList';
import useTags from '../utility/useTags';
import useUnits from '../utility/useUnits';
import useUser from '../utility/useUser';
import ShoppingListShareDialog from '../ui-elements/ShoppingListShareDialog';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import useSettings from '../hook/useSettings';
import useShowMessage from '../hook/useShowMessage';
import { ReceiptTwoTone } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ListDetailPageAutoAdd() {
  const { listId, recipeUrl } = useParams();
  const intl = useIntl();
  const listIdNumeric = parseInt(listId);
  const history = useHistory();
  const [shoppingListShareDialogOpen, setShoppingListShareDialogOpen] = React.useState(false);
  const [user, setUser] = useUser({ loginRequired: true });
  const [settings, setSettings] = useSettings();
  const [loading, setLoading] = React.useState(true);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [showMessage, setShowMessage] = useShowMessage();

  const onError = (error) => {
    setShowMessage({ open: true, severity: 'error', message: error.message });
    setLoading(false);
  };
  const [tags, setTags] = useTags(onError);
  //Menu initialization
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [sessionId] = React.useState(crypto.randomUUID());
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  //Data initialization
  const [shoppingList, setShoppingList] = useShoppingList(onError);
  const isShoppingListDisplayed = listIdNumeric === shoppingList?.listId;

  //TODO fix
  const [list, setList] = useList({ onError, listId: listIdNumeric, recipeUrl });
  const externalRecipeDisplayed = recipeUrl ? true : false;
  const unitList = useUnits({ onError });
  const [name, setName] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [content, setContent] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [comments, setComments] = useState([{ content: '' }]);
  const [priviledges, setPriviledges] = useState('read');

  React.useEffect(() => {
    //initial list load
    if (!_.isEmpty(list)) {
      /*content: (list.content ?? []).map((item) => {
        return { ...item, rowId: crypto.randomUUID(), version: 0 };
      })*/
      setName(list.name);
      setIsPublic(list.isPublic);
      const tagIds = (list.tags ?? []).map((item) => item.tagId);
      setSelectedTags(tags.filter((item) => tagIds.includes(item.tagId)));
      setContent(list.content ?? []);
      if (!_.isEmpty(list?.comments)) {
        const comments = [...list.comments] ?? [];
        if (comments.length == 0 || comments[comments.length - 1].content != '') comments.push({ content: '' });
        setComments(comments);
      }
      setTimeout(function () {
        setLoading(false);
        setTimeout(function () {
          handleAddToCart();          
        }, 1000);        
      }, 200);


      
    }
  }, [list?.version, list?.updatedOn]);

  React.useEffect(() => {
    return () => {
      if (externalRecipeDisplayed) {
        setList((args) => []);
      }
    };
  }, [listId]);

  React.useEffect(() => {
    if (listId === 'new' && _.isEmpty(recipeUrl) && loading) {
      setLoading(false);
      setName(intl.formatMessage({ id: 'listDetail.newList', defaultMessage: `New list` }));
    }
  }, [listId, recipeUrl]);

  React.useEffect(() => {
    if (user?.userId) {
      if (user?.userId === list?.userId) {
        setPriviledges('admin');
      } else if (user?.userId !== list?.userId || !list?.listId) {
        //not my list or external list
        setPriviledges('read');
      } //TODO add priviledge write for list shared for me
    }
  }, [user, list?.version]);

  /*React.useEffect(() => {
    if (!_.isEmpty(list?.comments)) {
      const comments = list.comments ?? [];
      if (comments.length == 0 || comments[comments.length - 1].content != '') comments.push({ content: '' });
      setComments(comments);
    }
  }, [list?.comments]);*/

  React.useEffect(() => {
    if (!loading) {
      triggerSave('Selected tags changed after first load.');
    }
  }, [selectedTags.length, _.sumBy(content ?? [], 'updatedOn')]);

  const triggerSave = (triggerSource = undefined) => {
    console.log(`Trigger save triggered by ${triggerSource}.`);
    const newList = { ...list, name, isPublic, content, tags: selectedTags, comments };
    newList.comments = comments.filter((comment) => comment.content != '');
    //setSl(newList);
    setList({ context: newList, action: 'modifyContent' });
  };

  const handleEmptyListClick = () => {
    setList({
      action: 'modifyContent',
      context: (list) => {
        return [];
      },
    });

    setAnchorElUser(null);
  };

  function renderSource() {
    if (list?.sourceUrl) {
      return (
        <Typography m={2} variant="caption">
          <Link href={list?.sourceUrl} variant="body2" target={'_blank'}>
            <FormattedMessage id="listeDetail.originalRecipe" defaultMessage="Original recipe" />
          </Link>
        </Typography>
      );
    } else {
      return (
        <Typography m={4} variant="caption">
          <FormattedMessage id="listDetail.createdBy" defaultMessage="Created by" />{' '}
          {list?.user
            ? list?.user?.name
            : intl.formatMessage({ id: 'listDetail.user', defaultMessage: 'user' }) + '(' + list?.userId + ')'}
        </Typography>
      );
    }
  }

  const handleDelete = async () => {
    try {
      setAnchorElUser(null);
      const previousId = settings?.activeShoppingListId.value;
      const currentId = list.listId;
      setShowMessage({
        message: intl.formatMessage({ id: 'listDetail.removingList', defaultMessage: 'Removing selected list' }),
        severity: 'info',
        open: true,
      });
      await removeList({ ...list });
      if (previousId == currentId) {
        const newSettings = { ...settings };
        delete newSettings.activeShoppingListId;
        setSettings(newSettings);
      }
      setShowMessage({
        message: intl.formatMessage({ id: 'listDetail.listRemoved', defaultMessage: 'List was removed.' }),
        severity: 'success',
        open: true,
      });
      history.push('/lists');
    } catch (error) {
      onError(error);
    } finally {
      setAnchorElUser(false);
    }
  };

  /*  const handleSubmit = async () => {
    console.log(JSON.stringify(list));
    try {
      await fetchApiPost('list/saveWrapper', {
        ...list,
        comments: (list.comments || []).filter((x) => x.content),
      });
      history.push('/lists');
    } catch (error) {
      onError(error);
    }
    setAnchorElUser(null);
  }; */

  const handleAddToCart = async () => {
    try {
     // setAnchorElUser(null);
     /* setShowMessage({
        open: true,
        severity: 'info',
        message: intl.formatMessage({
          id: 'listDetail.addingProductsIntoShoppingList',
          defaultMessage: 'Adding products into shopping list.',
        }),
      });*/
      const result = await addIngredients({
        list: shoppingList,
        newValues: list.content,
        onError,
        config: settings,
      });
      setShoppingList(result);
    /*  setShowMessage({
        open: true,
        severity: 'success',
        message: intl.formatMessage({
          id: 'listDetail.productsAddedIntoShoppingList',
          defaultMessage: 'Products added into shopping list.',
        }),
      });
      history.push(`/lists`);*/
    } catch (error) {
      onError(error);
    }
  };

  const changeActiveShoppingList = async (listId) => {
    //setActiveShoppingListId(listId);
    //const newShoppingList = await getActiveShoppingList();
    //setShoppingList(newShoppingList);
  };

  const handleShoppingListShareDialogOpen = () => {
    setShoppingListShareDialogOpen(true);
    setAnchorElUser(null);
  };

  const getListName = () => {
    return _.isEmpty(name) ? intl.formatMessage({ id: 'listDetail.loading', defaultMessage: 'Loading...' }) : name;
  };

  const refreshProductsHandler = async () => {
    try {
      setShowMessage({
        open: true,
        severity: 'info',
        message: intl.formatMessage({ id: 'listDetail.refreshingProducts', defaultMessage: 'Refreshing products.' }),
      });
      setAnchorElUser(null);
      const refreshedProductsList = await resetProducts(list, null, settings);
      setList({ ...list, content: refreshedProductsList.content, updatedOn: refreshedProductsList.updatedOn });
      setShowMessage({
        open: true,
        severity: 'success',
        message: intl.formatMessage({ id: 'listDetail.productsRefreshed', defaultMessage: 'Products refreshed.' }),
      });
    } catch (error) {
      onError(error);
    }
  };

  return (
    <AppLayout
      title={`${getListName()}`}
      mainIconMeaning={!isShoppingListDisplayed ? 'back' : ''}
      customButtons={
        <Box key="menu" sx={{ whiteSpace: 'nowrap' }}>
          {/*<IconButton color="inherit" onClick={handleSubmit}>
            <DoneIcon />
            </IconButton>*/}
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* <MenuItem onClick={handleSubmit}>
              <IconButton color="inherit">
                <DoneIcon />
              </IconButton>
              <Typography textAlign="center">Save list</Typography>
            </MenuItem> */}
            {priviledges === 'admin' && (
              <MenuItem onClick={() => handleShoppingListShareDialogOpen()}>
                <IconButton color="inherit">
                  <ShareIcon />
                </IconButton>
                <Typography textAlign="center">
                  <FormattedMessage id="listDetail.shareList" defaultMessage="Share list" />
                </Typography>
              </MenuItem>
            )}
            {!isShoppingListDisplayed && (
              <MenuItem onClick={() => handleAddToCart()}>
                <IconButton color="inherit">
                  <ShoppingCartIcon />
                </IconButton>
                <Typography textAlign="center">
                  <FormattedMessage id="listDetail.content.addToShoppingList" defaultMessage="Add to shopping list" />
                </Typography>
              </MenuItem>
            )}
            <MenuItem onClick={() => refreshProductsHandler()}>
              <IconButton color="inherit">
                <RefreshIcon />
              </IconButton>
              <Typography textAlign="center">
                <FormattedMessage id="listDetail.refreshProducts" defaultMessage="Refresh products" />
              </Typography>
            </MenuItem>
            {priviledges !== 'read' && list?.listId && shoppingList.listId != list.listId && (
              <MenuItem onClick={() => changeActiveShoppingList(list.listId)}>
                <IconButton color="inherit">
                  <ShoppingCartIcon />
                </IconButton>
                <Typography textAlign="center">
                  <FormattedMessage id="listDetail.changeIntoAShoppingList" defaultMessage="Change into a shopping list" />
                </Typography>
              </MenuItem>
            )}
            <Divider />
            {priviledges !== 'read' && (
              <MenuItem onClick={handleEmptyListClick}>
                <IconButton color="inherit">
                  <CleaningServicesIcon />
                </IconButton>
                <Typography textAlign="center">
                  <FormattedMessage id="listDetail.emptyList" defaultMessage="Empty list" />
                </Typography>
              </MenuItem>
            )}
            {priviledges === 'admin' && !externalRecipeDisplayed && shoppingList?.listId !== list?.listId && (
              <MenuItem onClick={handleDelete}>
                <IconButton color="inherit">
                  <DeleteIcon />
                </IconButton>
                <Typography textAlign="center">
                  <FormattedMessage id="listDetail.deleteList" defaultMessage="Delete list" />
                </Typography>
              </MenuItem>
            )}
          </Menu>
          <IconButton
            onClick={handleOpenUserMenu}
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Box>
      }
    >
      <ShoppingListShareDialog
        onClose={() => setShoppingListShareDialogOpen(false)}
        open={shoppingListShareDialogOpen}
        onFailed={onError}
        list={list}
      />

      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            marginTop: '20px',
          }}
        />
      )}

      {!loading && (
        <div>
          {priviledges === 'read' && (
            <Button
              onClick={() => triggerSave('Save button pressed.')}
              variant="contained"
              sx={{ position: 'relative', float: 'right', margin: '15px' }}
            >
              <FormattedMessage id="listDetail.saveToMyList" defaultMessage="Save to my list" />
            </Button>
          )}
          {!isShoppingListDisplayed && (
            <Button
              onClick={() => handleAddToCart()}
              variant="contained"
              sx={{ position: 'relative', float: 'right', margin: '15px' }}
            >
              <FormattedMessage id="listDetail.menu.addToShoppingList" defaultMessage="Add to shopping list"/>
            </Button>
          )}
          <Box m={2} sx={{ display: isShoppingListDisplayed ? 'none' : 'block' }}>
            <TextField
              label={intl.formatMessage({ id: 'listDetail.recipeName', defaultMessage: 'Recipe name' })}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={(e) => triggerSave('Recipe name changed.')}
            />
          </Box>
          {!isShoppingListDisplayed && (
            <Box sx={{ position: 'relative', float: 'right', margin: '15px', marginTop: '-10px' }}>
              {renderSource()}
            </Box>
          )}

          <IngredientsList
            data={content}
            setData={setContent}
            unitList={unitList}
            onError={onError}
            context={{ page: 'listDetailPage', listId: listId, recipeEditSessionId: sessionId }}
            showAsTable
          />

          {!isShoppingListDisplayed && (
            <Box>
              <Typography variant="h4" m={2}>
                <FormattedMessage id="listDetail.instructions" defaultMessage="Instructions" />
              </Typography>

              {comments.map((step, index) => (
                <Box key={'preparationStepBox' + index} m={2} sx={{ display: 'flex' }}>
                  <TextareaAutosize
                    label={`${index + 1}.`}
                    style={{ width: 'calc(100% - 50px)' }}
                    value={step.content}
                    onInput={(e) => {
                      setComments((ar) => {
                        const res = ar.map((it, ix) => (ix == index ? { ...it, content: e.target.value } : it));
                        if (res.length == 0 || res[res.length - 1].content != '') res.push({ content: '' });
                        return res;
                      });
                    }}
                    onBlur={() => triggerSave('Comment modified.')}
                  />
                  <IconButton
                    disabled={index == comments.length - 1 && comments[index].content == ''}
                    onClick={() => {
                      setComments((ar) => {
                        if (ar.length == 1) return [{ content: '' }];
                        return ar.filter((it, ix) => ix != index);
                      });
                    }}
                    onBlur={() => {
                      triggerSave('Comment removed.');
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}

              <Typography variant="h4" m={2}>
                <FormattedMessage id="listDetail.additionalOptions" defaultMessage="Additional options" />
              </Typography>
              <TagSelector selectedTags={selectedTags} setSelectedTags={setSelectedTags} label="Custom tags" />
              <Box m={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPublic}
                      onChange={(e) => {
                        setIsPublic(e.target.checked);
                      }}
                      onBlur={(e) => triggerSave('Is public property changed')}
                    />
                  }
                  label="Public"
                />
              </Box>
            </Box>
          )}
        </div>
      )}
    </AppLayout>
  );
}
