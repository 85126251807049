import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { messageHistoryState } from '../utility/atoms';
import { useState } from 'react';

export default function useShowMessage() {
  const [messageHistory, setMessageHistory] = useRecoilState(messageHistoryState);
  const [message, setMessage] = useState({});

  const setFunction = (message) => {
    message.messageId = crypto.randomUUID();
    message.createdOn = new Date();
    setMessage(message);
    if (_.isArray(messageHistory)) {
      const newMessageHistory = [...messageHistory];
      newMessageHistory.push(message);
      setMessageHistory(newMessageHistory);
    } else {
      setMessageHistory([message]);
    }
  };


  return [message, setFunction];
}
