import { fetchApiPost } from './fetchApi';
import { getShopNameFromUrl, shopsLib } from './shops';

export async function productChangedFeedback({ ingredient, newProduct, oldProduct, list, settings }) {
  const event = oldProduct ? 'productSwapped' : 'productMapped';
  const newFeedback = await createFeedbackObject({ingredient, newProduct, oldProduct, list, settings, event})  
  await fetchApiPost('feedbackProductIngredient/saveWrapper', newFeedback);
}

export async function productLikedFeedback({ ingredient, newProduct, list, settings }) {
  const newFeedback = await createFeedbackObject({ingredient, newProduct, list, settings, event: 'productLiked'})
  await fetchApiPost('feedbackProductIngredient/saveWrapper', newFeedback);
}

export async function productInsertedFeedback({ ingredient, newProduct, list, settings }) {
  const event = newProduct.processed ? 'productInserted' : 'productInsertFailed';
  const newFeedback = await createFeedbackObject({ingredient, newProduct, list, settings, event});  
  newFeedback['value'] = JSON.stringify({ processed: newProduct.processed, resultMessage: newProduct.resultMessage });  
  await fetchApiPost('feedbackProductIngredient/saveWrapper', newFeedback);
}

async function createFeedbackObject({ ingredient, newProduct, oldProduct, list, event, settings }){
  const newFeedback = {
    ingredientName: ingredient.name,
    ingredientId: ingredient.recipeIngredientId,
    ingredientDetail: JSON.stringify({ ingredientUnitId: ingredient.unitId, ingredientAmount: ingredient.amount }),
    productId: newProduct.productURL ?? newProduct.productUrl,
    productDetail: JSON.stringify({
      productAmount: newProduct.amount,
      productPrice: newProduct.price,
      productPricePerUnitPrice: newProduct["pricePerUnit.price"],
      productPricePerUnitUnit: newProduct["pricePerUnit.unit"],
      productQuantityUnit: newProduct["productQuantity.unit"],
      productQuantityValue: newProduct["productQuantity.value"],
      productScore: newProduct.customScore,
    }),
    listId: list?.listId,
    value: oldProduct
      ? JSON.stringify({
          replacedProductID: oldProduct.productURL ?? oldProduct.productUrl,
          replacedProductScore: oldProduct.customScore,
          replacedProductPrice: oldProduct.price,
          replacedProductPricePerUnitPrice: oldProduct["pricePerUnit.price"],
          replacedProductPricePerUnitUnit: oldProduct["pricePerUnit.unit"],
          replacedProductQuantityUnit: oldProduct["productQuantity.unit"],
          replacedProductQuantityValue: oldProduct["productQuantity.value"],
        })
      : '{}',
    listSessionId: settings?.currentListSessionId?.value,
    clientSessionId: settings?.clientId.value,
    productShopId: await shopsLib.getShopIdByName(getShopNameFromUrl(newProduct.productURL ?? newProduct.productUrl)),
    event
  };  
  return newFeedback;
}

