import { fetchApiGet } from "./fetchApi";
const _ = require('lodash');

export const shopsLib = {};

async function getShops(){
    if(_.isNil(shopsLib.shopList)){
        shopsLib.shopList = await fetchApiGet('shops/find');
    }    
    return shopsLib.shopList;
}

shopsLib.getShopIdByName = async (shopName) => {
  const currentShops = await getShops();
  const result = currentShops.find((shop) => shop.name === shopName);
  return _.isNil(result) ? null : result.shopId
};

shopsLib.getShopNameFromUrl = (url) => {
    const regex = /([a-z]*\.cz)\//
    const results = url.match(regex);
    return (results ?? []).length == 2 ? results[1] : null;
}

export function getShopNameFromUrl (url) {
    const regex = /([a-z]*\.cz)\//
    const results = url.match(regex);
    return (results ?? []).length == 2 ? results[1] : null;
}