import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Avatar, Card, CardContent, CardMedia, IconButton, Typography, Box } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import React, { useRef } from 'react';
import _ from 'lodash';
import useShops from '../utility/useShops';
import ProductImage from './ProductImage';
import usePrefferedShop from '../utility/usePrefferedShop';
import IngredientCard from './IngredientCard';
import IngredientPreview from './IngredientPreview';

export default function RecipeCard({
  title,
  content,
  imagePath,
  onError,
  onClick,
  externalUrl,  
  addToCartAction,
}) {
  const shopList = useShops({ onError });
  const [prefferedShop, setPrefferedShop] = usePrefferedShop({ onError });
  const prefferedShopName = prefferedShop?.name;
  let externalProviderLogo;
  if(externalUrl.includes('rohlik.cz/chef')){
    externalProviderLogo = 'https://www.rohlik.cz/favicon/cz/favicon-32x32.png';
  }else if (externalUrl.includes('toprecepty.cz')){
    externalProviderLogo =  '/toprecepty-icon.png';
  }else if(externalUrl.includes('svetreceptu.cz')){
    externalProviderLogo =  '/svetreceptu-icon.png';
  }  

  const componentId = useRef(crypto.randomUUID());
  const imageWidth = imagePath === '/no-photo-food.png' ? '96px' : '160px';
  let additionalContentText = '';
  let images = [];
  if (_.isArray(content) && _.isArray(shopList)) {
    let estimatedPrice = 0;
    content.forEach((item) => {
      let averageItemPrice = 0;
      let shopsWithPrice = 0;
      shopList.forEach((shop) => {
        if (item[shop['name']]?.price) {
          shopsWithPrice++;
          averageItemPrice += item[shop['name']].price;
        }
      });
      averageItemPrice = averageItemPrice / shopsWithPrice;
      estimatedPrice += averageItemPrice;
    });
    if (!_.isNaN(estimatedPrice)) estimatedPrice = `, ${parseFloat(estimatedPrice).toFixed(0)},-`;
    else estimatedPrice = '';
    additionalContentText = `(${content.length}) ingredients${estimatedPrice}`;
    images = content.map((item, index) => {
      if (item[prefferedShopName]?.imageUrl)
        return {
          imageUrl: item[prefferedShopName]?.imageUrl,
          productUrl: item[prefferedShopName]?.imageUrl,
          name: item[prefferedShopName]?.name,
          key: item.rowId + '_' + item.name,
        };
      else
        return {
          imageUrl: imagePath ?? '/no-photo-food.png',
          productUrl: imagePath ?? '/no-photo-food.png',
          name: 'product not found',
          key: item.rowId + '_' + item.name,
          content: content[index]
        };
    });
  }
  return (
    <Card
      sx={{
        ':hover': { boxShadow: 6 },
        boxShadow: 2,
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {externalProviderLogo && (
        <Avatar
          sx={{
            bgcolor: '#F5F5F5',
            position: 'absolute',
            left: '5px',
            top: '5px',
            padding: 1,
            width: '20px',
            height: '20px',
          }}
        >
          <IconButton
            onClick={() => {
              window.open(externalUrl, '_blank', 'noreferrer');
            }}
          >
            <img src={externalProviderLogo} style={{ width: '20px' }} aria-label="recipe" />
          </IconButton>
        </Avatar>
      )}
      <Box sx={{ height: '140px', display: 'flex', justifyContent: 'center' }}>
        {!content && (
          <CardMedia
            component="img"
            image={imagePath ?? '/no-photo-food.png'}
            alt={imagePath ? `Photo of recipe ${title}` : 'No photo for this recipe'}
            onClick={onClick}
            sx={{ '&:hover': { cursor: 'pointer' }, width: imageWidth, paddingTop: "1em" }}
          />
        )}
        {content && (
          <Box
            sx={{ width: '100%', height: 158, overflowY: 'hidden', overflowX: 'auto', '&:hover': { cursor: 'pointer' } }}
            onClick={onClick}
          >
            <ImageList variant="standard" cols={Math.ceil((content ?? []).length / 2)} gap={0} sx={{margin: "8px"}}>
              {images.map((item) => (
                <ImageListItem key={item.key} sx={{padding: "4px", textAlign: "center"}}>
                  {item.name !== 'product not found' ? (
                    <ProductImage product={item} />
                  ) : (
                    <IngredientPreview rowData={item?.content} onError={onError} />
                  )}
                </ImageListItem>
              ))}
            </ImageList>
          </Box>

          /* <CardMedia
          component="img"              
          image={contentCopy[0]["rohlik.cz"]?.imageUrl}
          alt={imagePath ? `Photo of recipe ${title}` : 'No photo for this recipe'}
          onClick={onClick}
          sx={{ '&:hover': { cursor: 'pointer' }, width: imageWidth }}
        />   */
        )}
      </Box>
      {addToCartAction && (
        <Avatar
          sx={{
            bgcolor: '#8bc34a',
            position: 'absolute',
            right: '5px',
            top: '5px',
            padding: 1,
            width: '20px',
            height: '20px',
          }}
          onClick={() => addToCartAction()}
        >
          <IconButton>
            <ShoppingCartIcon style={{ color: 'black' }} />
          </IconButton>
        </Avatar>
      )}
      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', '&:hover': { cursor: 'pointer' } }}
        onClick={onClick}
      >
        <Typography variant="body2">{title}</Typography>
        {content && (
          <Typography variant="body2" color="text.secondary">
            {additionalContentText}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
