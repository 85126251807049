import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { fetchApiPost } from '../utility/fetchApi';
import useSettings from '../hook/useSettings';
import useShoppingList from '../utility/useShoppingList';
import ProductImage from './ProductImage';
import _ from 'lodash';
import { reduceConfigForProductsLoading } from '../utility/listlib';
import ProductLike from './ProductLike';

export default function SuggestedProducts({ originalProductUrl, ingredient, shopId, userId, onClose }) {
  const [shoppingList, setShoppingList] = useShoppingList();
  const [suggestedProducts, setSuggestedProducts] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useSettings();
  //const [data, setData] = useState([]);
  /*React.useEffect(() => {
    setData(getActiveShoppingList());
  }, []);*/

  const filteredSuggestedProducts = (suggestedProducts ?? []).filter((item) => item.productUrl !== originalProductUrl)

  React.useEffect(() => {
    if (_.isObject(settings)) {
      async function fetchData() {
        const result = await fetchApiPost('pyapi/getProductsForIngredients', {
          ingredients: [ingredient],
          config: { maxProductsPerIngredient: 8, ...reduceConfigForProductsLoading(settings) },
        });
        if (result.hasOwnProperty(shopId)) setSuggestedProducts(result[shopId]);
        setLoading(false);
      }
      fetchData();
    }
  }, [shopId, settings?.adventurous?.value, settings?.pricePrefference?.value]);

  /*const handleProvideFeedback = async (name, productURL, ingredientId, feedbackValue, feeedbackEvent = "productSwap") => {
    const newFeedback = {
      ingredientName: ingredient.name,
      productId: productURL,
      ingredientId: ingredientId,
      productShopId: await shops.getShopIdByName(shopId),
      value: JSON.stringify(feedbackValue),
      event: feeedbackEvent,
    };
    fetchApiPost('feedbackProductIngredient/saveWrapper', newFeedback);
  };*/

  function handleProductClick(product) {
    setShoppingList({ action: 'replaceProduct', context: { ingredient, newProduct: product } });
    onClose();

    /*const existingIngredients = shoppingList.content.find((x) => x.name == ingredient.name);
    const param = {};
    if (existingIngredients && existingIngredients[shopId]) {//replacing product          
      param.replacedProductUrl = existingIngredients[shopId].productURL;
      param.replacedIngredientId = existingIngredients.recipeIngredientId;     
      param.replacedIngredientName = ingredient.name;     
      handleProvideFeedback(ingredient.name, product.productURL, existingIngredients.recipeIngredientId, param, "PI-Swap");
    } else {//adding new mapping
      handleProvideFeedback(ingredient.name, product.productURL, null, param, "PI-Map");
    }
    const shoppingList = new ShoppingList(data);
    const productsFormat = {};
    productsFormat[shopId] = [product];
    setData(shoppingList.assignProductToIngredient({name: ingredient.name, unitId: existingIngredients.unitId}, productsFormat));
    onClose(shoppingList.getIngredients());*/
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ padding: 1 }}>
        Best alternatives for {ingredient?.name}
      </Typography>
      <Divider></Divider>
      {loading && (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      )} : {' '}
      {
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'stretch', overflow: 'auto' }}>          
          {filteredSuggestedProducts.map((item, key) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  '&:hover': { boxShadow: 6, cursor: 'pointer', background: 'rgba(0, 0, 0, 0.02)' },
                  justifyContent: 'space-between',
                  padding: '4px',
                }}
                textAlign="center"
                onClick={() => {
                  handleProductClick(item);
                }}
                key={(Math.random() + 1).toString(36).substring(7)}
              >                                
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <ProductImage product={item} maxWidth={120} maxHeight={120} />
                  </Box>                  
                  <Box sx={{ position: 'absolute', top: '5px', right: '10px' }}>
                    <ProductLike
                      productId={item.productId}
                      productUrl={item.productUrl}
                      isLiked={item.isLiked}
                      shopId={shopId}
                      userId={userId}
                    />
                  </Box>  
                  <Box sx={{ position: 'absolute', top: '5px', left: '10px', backgroundColor: '#FFFFFF', opacity: '0.8', padding: '0.2em', borderRadius: '25%' }}>
                    <img src='/icon.png' width='16px'></img>&nbsp;
                    {item.customScore.toFixed(1)}
                  </Box>                
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', minWidth: '180px', width: `calc((95vw)/${(filteredSuggestedProducts??[]).length})`, maxWidth: '250px' }}>
                    <Typography variant="body2">{`${item.name}, ${item['productQuantity.value']} ${item['productQuantity.unit']}`}</Typography>
                    <Typography variant="body1" align="right">
                      {`${item.price.toFixed(2)},-`}
                    </Typography>
                    <Typography variant="body2" align="right">
                      {`(${item['pricePerUnit.price'].toFixed(2)} / ${item['pricePerUnit.unit']})`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>        
      }
    </Box>
  );
}
