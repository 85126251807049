import _ from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from './atoms';
import { fetchApiPost } from './fetchApi';

export default function useUser({ loginRequired = false }) {
  const [user, setUser] = useRecoilState(userState);
  const history = useHistory();

  useEffect(() => {
    if(!user && localStorage.getItem('user')){
      try{
        const cachedUser = JSON.parse(localStorage.getItem('user'));
        setUser({...cachedUser, loginStatus: "logging"});
      } catch (error) {
        console.error(error);
      }
    } else if(loginRequired && (user?.loginStatus === 'loggedOut' || _.isEmpty(user))){
      history.push('/authentication/login');        
    } 
  }, [user]);
  

  const refreshFunction = (param) => {    
    localStorage.setItem('user', JSON.stringify(param));
    setUser(param);
  }

  useEffect(() => {
    if (user && user.loginStatus === 'logging') {
      console.log('Validation current user login');
      fetchApiPost('user/validateLogin')
        .then((param) => {
          console.log('User login validated');
          setUser({...user, loginStatus: 'loggedIn'});
        })
        .catch((error) => {
          console.log(error);
          fetchApiPost('user/logout', { id: user.bearer });
          setUser({...user, loginStatus: 'loggedOut' });
        });
    }
  }, [user]);

  return [user, refreshFunction];
}
