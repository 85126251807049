import { ListItem, ListItemButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import React from 'react';
import ProductImage from './ProductImage';
import _ from 'lodash';
import ProductLike from './ProductLike';

export default function ProductList({ products, onProductClick, shopId, userId }) {
  shopId = shopId;
  return (
    <Box>
      {_.isArray(products) ? (
        <List sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }} component="nav" dense={true}>
          {products.map((product) => {
            const { productId, productUrl, imageUrl, name, price, quantity,isLiked } = product;
            return (
              <ListItem
                sx={{
                  ':hover': { boxShadow: 6, cursor: 'pointer', background: 'rgba(0, 0, 0, 0.02)' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  maxWidth: { xs: '50%', sm: '33%', md: '25%', lg: '20%', xl: '16,6%' },
                }}
                key={productUrl + product.name}
                onClick={() => onProductClick(product)}
              >
                <Box sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                  <ProductLike
                    productId={productId}
                    productUrl={productUrl}
                    isLiked={isLiked}
                    shopId={shopId}
                    userId={userId}
                  />
                </Box>

                <ProductImage product={product} maxWidth={100} maxHeight={100} />

                <Box>
                  <Typography variant="body2">{product.name}</Typography>
                  <Typography variant="body2">
                    {product?.['productQuantity.value']} {product?.['productQuantity.unit']}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'stretch',
                      alignItems: 'center',
                      flexDirection: 'column',
                      flexGrow: '1',
                      flexShrink: '1',
                      flexBasis: 'auto',
                    }}
                  >
                    <Typography variant="body1">{product.price.toFixed(2)},- </Typography>
                    <Typography variant="body2">
                      ({product['pricePerUnit.price']?.toFixed(2)},-/ {product['pricePerUnit.unit']})
                    </Typography>
                    {/* <Typography variant="body2">{product.category?.join('-')}</Typography> */}
                  </Box>
                </Box>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography variant="body2" m={2}>
          Error in search occured - server returned invalid value {JSON.stringify(products)}
        </Typography>
      )}
    </Box>
  );
}
