import { useTheme } from '@emotion/react';
import SettingsIcon from '@mui/icons-material/Settings';
import _ from 'lodash';
import {
  Box,
  Checkbox,
  Chip,
  darken,
  FormControl,
  InputLabel,
  lighten,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import useTags from '../utility/useTags';
import TagSettingsDialog from './TagSettingsDialog';

/*const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}*/

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TagSelector({
  selectedTags,
  setSelectedTags,
  label,
  sx = { width: '100%', margin: '0px' },
  onError,
}) {
  const [tags, setTags] = useTags({ onError });
  const theme = useTheme();
  const [tagSettingsDialogOpen, setTagSettingsDialogOpen] = useState(false);

  return (
    <Box m={2} sx={{ display: 'flex' }}>
      {/* <Autocomplete
        sx={sx}
        value={selectedTags}        
        onChange={(event, newValue) => {
          setSelectedTags(newValue);
        }}
        multiple
        disableListWrap        
        options={tags}
        // groupBy={(option) => option.category}
        isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            sx={{ width: 'calc(100%)', textOverflow: 'ellipsis',  whiteSpace: 'nowrap' }}
            {...params}
            label={label}
            placeholder=""
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      /> */}
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedTags}
          onChange={(event) => {
            const values = event.target.value;
            const newValue = event.target.value.slice(-1)?.[0];
            let newSelection = [];
            if(selectedTags.find(item => item.name === newValue)){//remove selection
              newSelection = selectedTags.filter(item => item.name !== newValue);
            }else{
              newSelection = values.map(itemToFind => _.isObject(itemToFind) ? itemToFind : tags.find(item => itemToFind === item.name));          
            }             
            setSelectedTags(newSelection);
          }}
          input={<OutlinedInput id="select-multiple-chip" label="Tags" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((tag) => (
                <Chip key={tag.tagId} label={tag.name} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {tags.map((tag) => (
            <MenuItem key={tag.tagId} value={tag?.name}>
              <Checkbox checked={(selectedTags ?? []).find(item => item.tagId === tag.tagId) !== undefined} />
              <ListItemText primary={tag?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <IconButton
        onClick={() => {
          setTagSettingsDialogOpen(true);
        }}
      >
        <SettingsIcon />
      </IconButton>
      <TagSettingsDialog
        onClose={() => setTagSettingsDialogOpen(false)}
        open={tagSettingsDialogOpen}
        onFailed={onError}
      />
    </Box>
  );
}
