import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function BottomSheet({ open, onClose, title, children, topContent }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Collapse in={open}>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            border: '2px solid #ccc',
            bgcolor: 'background.paper',
            boxShadow: 24,
            overflowX: 'auto',
            overflowY: 'auto',
            maxHeight: '90%'
          }}
        >
          {title != null && <Typography sx={{ m: 3, fontSize: '15pt', textAlign: 'center' }}>{title}</Typography>}
          {topContent}
          {children}
        </Box>
      </Collapse>
    </Modal>
  );
}
