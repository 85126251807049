import { Card, CardContent, Typography, Box, Icon, Avatar } from '@mui/material';
import { useRef } from 'react';
import _ from 'lodash';
import useShops from '../utility/useShops';
import usePrefferedShop from '../utility/usePrefferedShop';
import useUnits from '../utility/useUnits';
import { grey } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';
import { iconColors } from '../lib/visualSettingsForCategories';



export default function IngredientPreview({ rowData, onError, onClick }) {
  const units = useUnits({ onError });
  const name = rowData.name;
  const amount = rowData.amount;
  const unitId = rowData.unitId;
  let unitName = '';
  if (_.isArray(units)) {
    unitName = units?.find((item) => item.unitId === unitId)?.name;
  }
  return (
    <Card
      sx={{        
        justifyContent: 'space-around',
        position: 'relative',
        padding: '4px'
      }}
    >
      <Box sx={{ padding: "0px", margin: "0px"}}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
          <Avatar sx={{ bgcolor: iconColors[rowData.categoryName] ?? grey[100], width: "45px", height: "45px", borderRadius: "30%"  }}>
            <Box
              sx={{                
                display: 'flex',
                width: '100%',
                marginLeft: '5px',
                flexDirection: 'column', paddingBottom: '0px'
              }}
            >
              <Typography variant="body2" sx={{fontSize: '0.7rem', color: 'black'}}>{`${name}`}</Typography>
              <Typography variant="body2" color="text.secondary" sx={{fontSize: '0.5rem', color: 'gray'}}>
                {`${amount ?? ''}${unitName ?? ''}`}
              </Typography>              
            </Box>
          </Avatar>
        </Box>
      </Box>
    </Card>
  );
}
