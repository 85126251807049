import _ from 'lodash';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { shopsState } from '../../utility/atoms';
import { fetchApiGet } from '../../utility/fetchApi';


function ShopsProvider() {
  const [shops, setShops] = useRecoilState(shopsState);

  useEffect(() => {   
    const loadShops = async () => {
      const loadedData = await fetchApiGet('shops/find');
      if (_.isArray(loadedData)) {       
        const prefix = `${window.location.protocol}//${window.location.host}`
        const modifiedShops = loadedData.map(shop => {
          if(!shop.logoPath.toLowerCase().startsWith("http")){          
            shop.logoPath = `${prefix}/${shop.logoPath}`;
            shop.icon16 = `${prefix}/${shop.icon16}`;
            shop.icon32 = `${prefix}/${shop.icon32}`;
          }
          return shop;
        })
        setShops(loadedData);
      }
    };
    loadShops();
  }, [])  

  return <div />;
}

export default ShopsProvider;
