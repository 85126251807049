import {
  Avatar,
  Box,
  DialogTitle,
  FormControl,
  IconButton,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AutocompleteField from './AutocompleteField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import useUnits from '../utility/useUnits';

import { useEffect, useState } from 'react';
export default function IngredientDetailDialog({ open, handleClose, onError, ingredient }) {
  const intl = useIntl();
  const unitList = useUnits(onError);
  const [apiResult, setApiResult] = useState(null);
  const [currentAmount, setCurrentAmount] = useState();
  const [currentUnitId, setCurrentUnitId] = useState();
  const [currentIngredient, setCurrentIngredient] = useState();

  useEffect(() => {
    setCurrentAmount(ingredient?.amount);
    setCurrentUnitId(ingredient?.unitId);
    setCurrentIngredient(ingredient);
  }, [ingredient]);

  const baseUnits = _.isArray(unitList) ? unitList.filter(item => item.type === 'basic').sort((a, b) => a.name < b.name) : [];
  const recipeUnits = _.isArray(unitList) ? unitList.filter(item => item.type === 'recipe').sort((a, b) => a.name < b.name) : [];

  function calculateStep(value, add = true) {
    // Definujeme funkci pro výpočet kroku na základě aktuální hodnoty
    function getStep(value) {
      if (value < 30) return 1;
      if (value < 100) return 10;
      if (value < 2000) return 100;
      if (value < 5000) return 500;
      return 1000;
    }

    if (!add) {
      let nextStep = getStep(value - 1);
      let currentStep = getStep(value);
      if (nextStep != currentStep) {
        return value - nextStep < 0 ? 0 : value - nextStep;
      }
      return value - currentStep < 0 ? 0 : value - currentStep;
    }
    return value + getStep(value) < 0 ? 0 : value + getStep(value);
  }

  const getUnitNameByID = (id) => {
    const result = unitList?.find((element) => element.unitId == id);
    if (result) return result['name'];
  };

  return (
    <Dialog
      onClose={() => {
        handleClose({
          name: currentIngredient.name,
          category: currentIngredient.categoryName,
          recipeIngredientId: currentIngredient.recipeIngredientId,
          unitId: currentUnitId,
          amount: currentAmount,
        });
        setCurrentIngredient(null);
        setCurrentAmount(null);
        setCurrentUnitId(null);
      }}
      open={open}
    >
      <DialogTitle>{currentIngredient?.name}</DialogTitle>

      {!ingredient?.recipeIngredientId && (
        <AutocompleteField
          url="pyapi/autocompleteIngredient"
          getUrlParams={(freeText) => ({ freeText })}
          value={apiResult?.name || ingredient?.name}
          setValue={(value) => {
            if (value) {
              setCurrentUnitId(value.unit[0]);
              //setAmount(value.estimatedAmount);
              setCurrentIngredient(value);
              setApiResult(value);
            }
          }}
          primaryField="name"
          secondaryField="categoryName"
          textField="name"
          label={intl.formatMessage({ id: 'ingredientsList.modifyIngredient', defaultMessage: 'Modify ingredient' })}
        />
      )}
      <Box display={'flex'} flexDirection={'row'}>
        <FormControl>
          <Box display={'flex'} flexDirection={'row'}>
            <IconButton
              sx={{ m: 1, margin: '4px' }}
              onClick={() => {
                setCurrentAmount(calculateStep(Number(currentAmount), true));
              }}
            >
              <AddCircleIcon />
            </IconButton>
            <TextField
              label={intl.formatMessage({ id: 'ingredientsList.amount', defaultMessage: 'Amount' })}
              variant="outlined"
              type="number"
              sx={{
                m: 2,
                marginLeft: '0px',
                marginRight: '0px',
                maxWidth: '7em',
                minWidth: '5em',
                textAlign: 'center',
              }}
              value={currentAmount}
              onChange={(ev, val) => {
                setCurrentAmount(ev.target.value);
              }}
            />
            <IconButton
              sx={{ m: 1 }}
              onClick={() => {
                setCurrentAmount(calculateStep(Number(currentAmount), false));
              }}
            >
              <RemoveCircleIcon />
            </IconButton>
          </Box>
        </FormControl>

        <FormControl sx={{ m: 2, minWidth: '6em' }}>
          <InputLabel id="unit-options" sx={{zIndex:100,opacity:1, backgroundColor: '#FFFFFF', paddingLeft: '0.2em', paddingRight: '0.2em'}}>Unit</InputLabel>
          <Select
            value={getUnitNameByID(currentUnitId)}
            isOptionEqualToValue={(option, value) => option.name == value}
            labelId= 'unit-options'
          >
            {baseUnits?.map((item) => (
              <MenuItem
                key={item.unitId}
                value={item.name}
                onClick={(ev, val, reason) => {
                  setCurrentUnitId(item.unitId);
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Dialog>
  );
}
