import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { fetchApiGet } from '../../utility/fetchApi';
import { unitsState } from '../../utility/atoms';


function UnitProvider() {
  const [units, setUnits] = useRecoilState(unitsState);  

  useEffect(() => {
    const loadUnits = async () => {
      const loadedData = await fetchApiGet('unit/find');
      if (_.isArray(loadedData) && loadedData.length > 0) {
        setUnits(loadedData);
      }
    };
    if(_.isEmpty(units)) loadUnits();
  }, []);  

  return <div />;
}

export default UnitProvider;
