import { grey, red, green, yellow, orange, blue, brown, pink } from '@mui/material/colors';

export const iconColors = {
  'Koření a ochucovadla': grey[400],
  Luštěniny: green[200],
  Maso: red[500],
  Zelenina: green[800],
  Ovoce: red['A100'],
  Vejce: yellow[600],
  'Mléčné výrobky': grey[200],
  'Obiloviny lepkové': orange[500],
  'Tuky a oleje': yellow[200],
  'Ryby a plody moře': blue[500],
  Aditiva: brown[200],
  Houby: brown[500],
  Tekutiny: blue[200],
  'Ořechy a semena': brown[800],
  Polotovary: grey[800],
  'Doplňky stravy': grey[1000],
  Zmrzliny: pink[500],
};
