import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Autocomplete, Box, IconButton, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React from 'react';
import { iglib } from '../utility/iglib';
import useFetchApi from '../utility/useFetchApi';
import useShops from '../utility/useShops';
import AddIngredientField from './AddIngredientField';
import BottomSheet from './BottomSheet';
import ListMenu from './ListMenu';
import usePrefferedShop from '../utility/usePrefferedShop';
import IngredientCard from './IngredientCard';
import { useIntl } from 'react-intl';
import IngredientDetailDialog from './IngredientDetailDialog';

function cmpStr(a, b) {
  if (a === b) {
    return 0;
  } else if (a < b) {
    return -1;
  } else {
    return 1;
  }
}

export default function IngredientsList({
  data,
  setData,
  unitList,
  menuItems,
  showAsTable,
  onError,
  context,
  displayFilter = (item) => item,
  autocompleteUrl = 'pyapi/autocompleteShoppingListIngredient',
}) {
  //var orderedData = (data ?? []);
  const intl = useIntl();
  const showAsBoxes = true;
  const orderedData = ([...(data ?? [])] ?? [])
    .sort((a, b) => {
      const nameA = a?.name;
      const nameB = b?.name;
      const catA = _.isArray(a.categoryName) ? a.categoryName?.[0] : a.categoryName;
      const catB = _.isArray(b.categoryName) ? b.categoryName?.[0] : b.categoryName;
      if (catA === catB) {
        return cmpStr(nameA, nameB);
      } else {
        return cmpStr(catA, catB);
      }
    })
    .filter(displayFilter);
  const groupedData = _.groupBy(orderedData, 'categoryName');

  const shopList = useShops({ onError });
  const getShopByUrl = (shopId) => {
    return _.isArray(shopList) && shopList.length > 0 ? shopList.find((shop) => shop.url.includes(shopId)) : {};
  };
  const [selectedForMenu, setSelectedForMenu] = React.useState(false);
  const getUnitNameByID = (id) => {
    const result = unitList?.find((element) => element.unitId == id);
    if (result) return result['name'];
  };

  const [apiResult, setApiResult] = React.useState(null);
  const [prefferedShop, setPrefferedShop] = usePrefferedShop({ onError });

  const [unitId, setUnitId] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [rowId, setRowId] = React.useState(null);
  const [version, setVersion] = React.useState(null);

  const [editingKey, setEditingKey] = React.useState(null);
  const [autocompleteKey, setAutocompleteKey] = React.useState(0);

  async function handleIngredientChange({ name, category, recipeIngredientId, unitId, amount }) {
    console.log(`Handling submit function.`);
    const item = {
      rowId,
      name: name,
      categoryName: category,
      recipeIngredientId: recipeIngredientId,
      version: version + 1,
      updatedOn: new Date().getTime(),
      unitId,
      amount,
    };

    if (selectedForMenu) {
      setData((x) => {
        // console.log(`Writing ${JSON.stringify(x)}`);
        return x.map((y) => (y === selectedForMenu ? item : y));
      });
    } /* else {
      console.log(`Not writing`);
      setData((x) => [...x, {...item, rowId: crypto.randomUUID()}]);
    }*/
  }

  async function handleInitialSubmit(item) {
    let index = (orderedData ?? []).findIndex((existing) => existing.name === item.name);
    if (index >= 0) {
      setAmount(Number(orderedData.at(index).amount) + Number(item.amount));
      setUnitId(item.unitId);
      setSelectedForMenu(orderedData.at(index));
      setEditingKey(orderedData.at(index));
      setRowId(orderedData.at(index).rowId);
      setVersion(orderedData.at(index).version);
    } else {
      setData((x) =>
        iglib.reduceIngredientList([
          ...x,
          { ...item, rowId: crypto.randomUUID(), version: 0, updatedOn: new Date().getTime() },
        ])
      );
    }
    setApiResult(null);
  }

  function handleRemoveButton() {
    setData((x) => x.filter((y) => y !== selectedForMenu));
  }

  function handleCloseMenu() {
    setSelectedForMenu(null);
    setApiResult(null);
    setEditingKey(null);
    setRowId(null);
    setVersion(null);
    setAutocompleteKey((x) => x + 1);
  }

  function renderCustomInfo(ingredient) {
    const shops = Object.keys(ingredient).filter((item) => _.isObject(ingredient[item]));

    if (prefferedShop) {
      const prefferedProduct = ingredient[prefferedShop.name];
      if (!prefferedProduct) return;
      return (
        <span>
          <Tooltip title="Matching ingredient">
            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
              <img src={prefferedShop.icon16} width="16px" />
              &nbsp;&nbsp;{(parseFloat(prefferedProduct.price) * (prefferedProduct.amount ?? 1)).toFixed(2)},-
            </Typography>
          </Tooltip>
        </span>
      );
    } else if (shops.length > 0) {
      const bestShop = shops
        .map((item) => ingredient[item])
        .sort((a, b) => (a['pricePerUnit.price'] > b['pricePerUnit.price'] ? -1 : 1))[0];
      const shopName = bestShop?.source + '.cz';
      let iconSrc = getShopByUrl(shopName)?.icon16;

      return (
        <span>
          <Tooltip title="The best matching ingredient">
            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
              <img src={iconSrc} width="16px" />
              &nbsp;&nbsp;{(parseFloat(bestShop.price) * (bestShop.amount ?? 1)).toFixed(2)},-
            </Typography>
          </Tooltip>
        </span>
      );
    }
  }

  return (
    <>
      <AddIngredientField
        url={autocompleteUrl}
        key={autocompleteKey}
        getUrlParams={(freeText) => ({ freeText })}
        value={apiResult != null ? apiResult.name : null}
        setValue={(value) => {
          if (value) {
            const item = {
              name: value.name,
              categoryName: value.categoryName,
              recipeIngredientId: value.recipeIngredientId,
              unitId: value.unit[0],
              amount: value.estimatedAmount,
            };
            handleInitialSubmit(item);
          }
        }}
        primaryField="name"
        secondaryField="categoryName"
        textField="name"
        label={intl.formatMessage({ id: 'ingredientList.addNewIngredient', defaultMessage: 'Add new ingredient' })}
        onError={onError}
        unitList={unitList}
      />

      {Object.keys(groupedData).map((categoryName) => (
        <Box>
          {!_.isEmpty(categoryName) && categoryName !== 'undefined' ? (
            <Typography m={2}>{categoryName}</Typography>
          ) : (
            <></>
          )}

          {!showAsBoxes && (
            <List>
              {groupedData[categoryName].map((ing, index) => (
                <ListItem
                  key={ing.rowId ?? index}
                  gro
                  button
                  onClick={() => {
                    setUnitId(ing.unitId);
                    setAmount(ing.amount);
                    setSelectedForMenu(ing);
                    setRowId(ing.rowId);
                    setVersion(ing.version);
                    setEditingKey(ing);
                  }}
                >
                  {showAsTable ? (
                    <Grid container m={2} sx={{ maxWidth: 'calc(100% - 16px)' }}>
                      <Grid item xs={1}>
                        {ing.amount}
                      </Grid>
                      <Grid item xs={2}>
                        {getUnitNameByID(ing.unitId)}
                      </Grid>
                      <Grid item xs={7}>
                        {ing.name}
                      </Grid>
                      {_.isNil(ing.recipeIngredientId) || ing.recipeIngredientId == '' ? (
                        <Grid item xs={1} textAlign="center">
                          <Tooltip title="Custom ingredient">
                            <HelpOutlineIcon />
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Grid item xs={1} textAlign="center">
                          {renderCustomInfo(ing)}
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <>
                      <ListItemIcon>
                        <EmojiFoodBeverageIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={ing.name}
                        secondary={`${ing.amount ?? ''} ${getUnitNameByID(ing.unitId) ?? ''}`}
                      />
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          )}
          {showAsBoxes && (
            <List
              key="myLists"
              sx={{
                display: 'grid',
                gridGap: '8px',
                gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 2fr))', //grid automagic
                justifyContent: 'space-around',
                padding: '8px',
              }}
              component="nav"
            >
              {groupedData[categoryName].map((ing, index) => (
                <IngredientCard
                  amount={ing.amount}
                  name={ing.name}
                  onError={onError}
                  unitId={ing.unitId}
                  rowData={ing}
                  onRemove={() => {
                    setData((x) => x.filter((y) => y !== ing));
                  }}
                  onClick={() => {
                    setUnitId(ing.unitId);
                    setAmount(ing.amount);
                    setSelectedForMenu(ing);
                    setRowId(ing.rowId);
                    setVersion(ing.version);
                    setEditingKey(ing);
                  }}
                />
              ))}
            </List>
          )}
        </Box>
      ))}
      <IngredientDetailDialog
        ingredient={editingKey}
        open={!!editingKey}
        onError={() => onError()}
        handleClose={({ name, category, recipeIngredientId, unitId, amount }) => {  
          setEditingKey(null);
          if(recipeIngredientId !== editingKey?.recipeIngredientId || unitId !== editingKey?.unitId || amount !== editingKey?.amount){
            handleIngredientChange({ name, category, recipeIngredientId, unitId, amount });
          }          
          
        }}
      />
      {/*  <BottomSheet open={!!editingKey} onClose={handleCloseMenu} title={editingKey?.name}>
        {!editingKey?.recipeIngredientId && (
          <AutocompleteField
            url="pyapi/autocompleteIngredient"
            key={autocompleteKey}
            getUrlParams={(freeText) => ({ freeText })}
            value={apiResult?.name || editingKey?.name}
            setValue={(value) => {
              if (value) {
                setUnitId(value.unit[0]);
                //setAmount(value.estimatedAmount);
                setEditingKey(value);
                setApiResult(value);
              }
            }}
            primaryField="name"
            secondaryField="categoryName"
            textField="name"
            label={intl.formatMessage({ id: 'ingredientsList.modifyIngredient', defaultMessage: 'Modify ingredient' })}
          />
        )}

        {/*<Autocomplete
          label="Unit"
          options={
            unitList.map((item) => ({name: item.name, value: item.unitId, label: item.name}))
            /*apiResult
              ? apiResult.unit.map((x) => ({ name: getUnitNameByID(x), value: x }))
            : [{ name: getUnitNameByID(unitId), value: unitId }]          
              : [{ name: getUnitNameByID(unitId), value: unitId }]
            : [{ name: getUnitNameByID(unitId), value: unitId }]          
          }
          disabled={!apiResult}
          value={unitId}
          setValue={(ev, x) => {
            setUnitId(ev.target.value);
          }}
        />

        <Box display={'flex'} flexDirection={'row'}>
          <FormControl>
            <Box display={'flex'} flexDirection={'row'}>
              <IconButton sx={{ m: 1, maring: '4px' }} onClick={() => {}}>
                <AddCircleIcon />
              </IconButton>
              <TextField
                label={intl.formatMessage({ id: 'ingredientsList.amount', defaultMessage: 'Amount' })}
                variant="outlined"
                type="number"
                sx={{
                  m: 2,
                  marginLeft: '0px',
                  marginRight: '0px',
                  maxWidth: '7em',
                  minWidth: '5em',
                  textAlign: 'center',
                }}
                value={amount}
                onChange={(ev, val) => {
                  setAmount(ev.target.value);
                }}
              />
              <IconButton sx={{ m: 1 }} onClick={() => {}}>
                <RemoveCircleIcon />
              </IconButton>
            </Box>
          </FormControl>
          <Autocomplete
            id="unit-autocomplete"
            sx={{ m: 2 }}
            value={getUnitNameByID(unitId)}
            isOptionEqualToValue={(option, value) => option.name == value}
            options={unitList?.map((item) => ({ ...item, label: item.name }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'ingredientsList.unit', defaultMessage: 'Unit' })}
              />
            )}
            onChange={(ev, val, reason) => {
              if (val) {
                setUnitId(val.unitId);
              } else {
                setUnitId(null);
              }
            }}
          />
        </Box>

        {/* <ListMenu
          onClose={handleCloseMenu}
          items={[
            ...(menuItems ? menuItems(selectedForMenu) : []),
            {
              icon: DoneIcon,
              text: intl.formatMessage({id: "ingredientsList.submit", defaultMessage: "Submit"}),
              onClick: handleSubmit,
            },
            {
              icon: DeleteIcon,
              text: intl.formatMessage({id: "ingredientsList.remove", defaultMessage: "Remove"}),
              onClick: handleRemoveButton,
            },
            {
              icon: CloseIcon,
              text: intl.formatMessage({id: "ingredientsList.close", defaultMessage: "Close"}),
              onClick: handleCloseMenu,
            },
          ]} 
        />
      </BottomSheet> */}
    </>
  );
}
