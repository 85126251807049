import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import useSettings from '../hook/useSettings';
import { Box, Icon, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  flexLine: {
    display: 'flex',
    width: "100%"
  },
  searchField: {
    marginLeft: '1rem',
    // marginRight: '1rem',
    marginTop: '1rem',
    flexGrow: 1,
  },
}));

export function getSearchValue(history, routerStateField) {
  const search = history.location && history.location.state && history.location.state[`${routerStateField}_search`];
  return search;
}

export default function SearchField({ routerStateField = undefined, onRunFilter, className = undefined, historyId }) {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();


  const [settings, setSettings] = useSettings();
  const [settingsApplied, setSettingsApplied] = React.useState(false);

  const [searchInState, setSearchInState] = React.useState('');
  const setSearch = (value) => {
    const result = routerStateField
    ? history.replace(history.location.pathname, { ...history.location.state, [`${routerStateField}_search`]: value })
    : setSearchInState(value);
    if(historyId){
      setSettings({...settings, [historyId]: {value: value, scope: "local"}});
    }
    return result;
  }
   
  const search = routerStateField ? getSearchValue(history, routerStateField) : searchInState;

  const setDebouncedFilter = React.useRef(
    // @ts-ignore
    _.debounce((value) => onRunFilter(value), 500)
  );

  const applyFilter = () => {
    onRunFilter(search);
  };
  const clearFilter = () => {
    setSearch('');
    onRunFilter('');
  };

  useEffect(() => {
    if(historyId && !settingsApplied && settings?.[historyId]?.value){ //apply once during load
      setSettingsApplied(true);
      setSearch(settings?.[historyId]?.value);
      onRunFilter(settings?.[historyId]?.value);
    }
  }, [_.isUndefined(settings?.[historyId]?.value)]);

  return (
    <div className={clsx(classes.flexLine, className)} style={{margin: 16, width: 'calc(100% - 32px)'}}>
      <TextField
        id="outlined-with-placeholder"
        placeholder={intl.formatMessage({id: 'searchField.search', defaultMessage: 'Search'})}
        className={classes.searchField}
        value={search}
        autoComplete="off"
        variant={"outlined"}
        InputProps={{
          startAdornment: <InputAdornment position="start"><Box><Icon><SearchIcon></SearchIcon></Icon></Box></InputAdornment>,
        }}
       
        onChange={(e) => {
          setSearch(e.target.value);
          // onRunFilter(e.target.value);
          // @ts-ignore
          setDebouncedFilter.current(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') applyFilter();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') clearFilter();
        }}
      />
      {/* {
        // @ts-ignore
        <IconButton aria-label="menu" color="primary" onClick={openAddFilter}>
          <AddCircleIcon />
        </IconButton>
      }

      <ChooseFromListDialog items={chooseItems} {...addFilterDialogProps} /> */}
    </div>
  );
}
