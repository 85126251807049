import React from 'react';
import BottomSheet from './BottomSheet';
import ListMenu from './ListMenu';

export default function BottomSheetMenu({ title, items, open, onClose, topContent }) {
  return (
    <BottomSheet open={open} onClose={onClose} title={title}  topContent={topContent}>
      <ListMenu onClose={onClose} items={items} />
    </BottomSheet>
  );
}
