import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import ProductList from '../ui-elements/ProductList';
import useFetchApi from '../utility/useFetchApi';
import useShoppingList from '../utility/useShoppingList';
import useUser from '../utility/useUser';
import { FormattedMessage, useIntl } from 'react-intl';

export default function FindProductPage() {
  const { shopId, rowId } = useParams();
  const intl = useIntl();
  const [shoppingList, setShoppingList] = useShoppingList();
  const ingredient = shoppingList?.content?.find((item) => item.rowId === rowId);
  const ingredientName = ingredient?.name;
  const history = useHistory();
  const [containsFilter, setContainsFilter] = React.useState(ingredientName);
  const setDebouncedContainsFilter = React.useRef(_.debounce((value) => setContainsFilter(value), 300));
  const [userSettingsData, setUserSettingsData] = useUser({ loginRequired: true });
  const [filter, setFilter] = React.useState(ingredientName);
  React.useEffect(() => {
    setDebouncedContainsFilter.current(filter);
  }, [filter]);
  const allProducts = useFetchApi(
    shopId ? 'pyapi/autocompleteProduct' : null,
    { freeText: containsFilter, shopId: shopId },
    []
  );
  const products = allProducts ?? [];

  function handleProductClick(product) {
    setShoppingList({ action: 'replaceProduct', context: { ingredient, newProduct: product } });
    history.goBack();
  }

  return (
    <AppLayout title={intl.formatMessage({id: 'findProduct', defaultMessage: `Find product`})} mainIconMeaning="back" customButtons={<Box sx={{ whiteSpace: 'nowrap' }}></Box>}>
      <Typography m={2}>
        {intl.formatMessage(
            {
              id: 'findProduct.searchForInShop',
              defaultMessage: 'Search for ingredient \'{ingredientName}\' in shop \'{shopId}\'',
            },
            { ingredientName, shopId }
          
          )}
      </Typography>

      <Box m={2}>
        <TextField label={intl.formatMessage({id: 'findProduct', defaultMessage: `Find product`})} fullWidth value={filter} onInput={(e) => setFilter(e.target.value)} />
      </Box>

      {products.length > 0 ? (
        <ProductList
          key="productList"
          products={products}
          onProductClick={handleProductClick}
          shopId={shopId}
          userId={userSettingsData?.userId}
        />
      ) : (
        <Typography variant="h6" component="div" m={2}>
          <FormattedMessage id="findProduct.noProductsFound" defaultMessage="No relevant products found in shop." />
        </Typography>
      )}
    </AppLayout>
  );
}
