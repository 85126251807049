import { atomFamily, selectorFamily, useRecoilState, useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import { serverEventSourceDataState } from '../../utility/atoms';
import { useEffect } from 'react';

const listStateSelectorFamily = selectorFamily({
  key: 'listStateSelector',
  get: (id) => ({ get }) => {
    return get(listStateAtomFamily(id));
  },
  set: (id) => ({ get, set }, newValue) => {
    const currentState = get(listStateAtomFamily(id));
    set(listStateAtomFamily(id), { ...currentState, value: newValue });
  },
});

const listStateAtomFamily = atomFamily({
  key: 'listStateAtom',
  default: { id: null, value: null },
});

export default function ListProvider() {
 /* const [serverData, setServerData] = useRecoilState(serverEventSourceDataState);
  const [listStatesLoadables, setListStatesLoadables] = useRecoilStateLoadable(
    listStateSelectorFamily(null)
  );

  useEffect(() => {
    if(!serverData) return;
    

  }, [serverData?.messageId])

  const setListStateById = useSetRecoilState(listStateSelectorFamily(null));

  function handleExternalEvent(eventData) {
    const listStatesToUpdate = eventData.map(({ id, value }) => ({ id, value }));

    listStatesToUpdate.forEach(({ id, value }) => {
      setListStateById(id, value);
    });
  }*/

  // Render the components for each object ID
  return (
    <div>
      {/*listStatesLoadables.map((listStateLoadable, index) => (
        <div key={index}>
          {listStateLoadable.contents && listStateLoadable.contents.value}
        </div>
      ))*/}
    </div>
  );
}



