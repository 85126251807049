import { CheckBox } from "@mui/icons-material";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { fetchApiPost } from "../utility/fetchApi";
import useSettings from "../hook/useSettings";
import useShowMessage from '../hook/useShowMessage';

export default function ShopsCredentialsDialog({ onError, open, handleClose, shop }) {
  const [connecting, setConnecting] = useState(false);
  const [settings, setSettings] = useSettings();
  const [showMessage, setShowMessage] = useShowMessage();
  const intl = useIntl();

  async function saveSecret(objectToStore) {    
    try{
      setConnecting(true);
      const result = await fetchApiPost('userSecret/saveUserSecretWrapper', objectToStore);
      const newSettings = { ...settings };      
      const objectForSettings = {...objectToStore}
      delete objectForSettings.password;
  
      if(!newSettings.shopConfiguration){
        newSettings.shopConfiguration = { value: {[shop.shopId]: objectForSettings}, scope: 'server' }
      }else{
        newSettings.shopConfiguration = { scope: 'server', value: {...newSettings.shopConfiguration.value, [shop.shopId]: objectForSettings}}
      }    
      setSettings(newSettings);      
      setShowMessage({message: intl.formatMessage({
        id: 'userSettings.CredentialsDialog.configurationSaved',
        defaultMessage: `Configuration for shop saved.`}), severity: `success`});
      return result;
    } catch (e){
      setShowMessage({message: intl.formatMessage({
        id: 'userSettings.CredentialsDialog.configurationFiledToSave',
        defaultMessage: `Unable to save configuration.`})  + ": " + e.message, severity: `error`});
    } finally {
      setConnecting(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setConnecting(false);
        handleClose();
      }}
      PaperProps={{
        component: 'form',
        onSubmit: async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const objectToStore = {
            username: formJson.username,
            password: formJson.password,
            shopId: shop.shopId
          }
          const result = await saveSecret(objectToStore);
          handleClose();
        },
      }}
    >
      <DialogTitle><FormattedMessage id='userSettings.CredentialsDialog.header' defaultMessage='Connect shop'/> {shop?.name} </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id='userSettings.CredentialsDialog.explainText' defaultMessage='To connect with selected shop, you need to enter your shop credentials.'/>
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="username"
          name="username"
          label="Username"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          required
          margin="dense"
          id="password"
          name="password"
          label="Password"
          type="password"
          fullWidth
          variant="standard"
        />
        <FormControlLabel id="allowSavePassword" name="allowSavePassword" control={<Checkbox defaultChecked disabled />} label="Save your password" />
        <FormControlLabel id="allowCheckHistory" name="allowCheckHistory" control={<Checkbox disabled />} label="Allow YumDroid to check your shopping history" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}><FormattedMessage id='userSettings.CredentialsDialog.cancel' defaultMessage='Cancel'/></Button>
        <Button type="submit">
          {connecting && <CircularProgress />}
          <FormattedMessage id='userSettings.CredentialsDialog.save' defaultMessage='Save'/></Button>
      </DialogActions>
    </Dialog>
  );
}
