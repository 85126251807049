import { useEffect, useState } from 'react';
import { fetchApiGet, fetchApiPost } from './fetchApi';
import { extendListWithRowId, makeListChange, fillProducts, createEmptyList } from './listlib.js';
import useShops from './useShops';
import _ from 'lodash';
import useSettings from '../hook/useSettings';
import { listStateFamily, serverListState } from './atoms';
import { useRecoilState } from 'recoil';
import { shopsLib } from './shops';
import { productChangedFeedback } from './feedback';
import useShowMessage from '../hook/useShowMessage';
import { useIntl } from 'react-intl';

export default function useList({ onError, listId, recipeUrl }) {
  const intl = useIntl();
  const [list, setList] = useRecoilState(listStateFamily(listId));
  const [showMessage, setShowMessage] = useShowMessage();
  const [severList, setSeverList] = useRecoilState(serverListState);
  const [settings, setSettings] = useSettings();
  const shops = useShops({ onError });

  useEffect(() => {
    if (severList?.content?.listId === listId && listId) {
      const evaluateChange = async () => {
        console.log(`List (${listId}) is displayed and was updated on server. Checking the impact!!!`);
        const modifiedList = await makeListChange({
          oldList: list,
          newList: severList.content,
          onError,
          config: settings,
          params: { suppressProductsReload: true, suppressPersist: true, returnOnChangeOnly: true },
        });
        if (modifiedList) {
          const newFinalList = { ...modifiedList };
          setList(newFinalList);
          console.log(`Changes are evaluated. List version is ${newFinalList?.version}`);
        }
      };
      evaluateChange();
    }
  }, [severList]);

  useEffect(() => {
    if (!_.isEmpty(recipeUrl)) {
      //create list using external
      const loadList = async () => {
        const loadedData = await fetchApiPost('recipe-ext/parseRecipeUrl', {
          recipeUrl: decodeURIComponent(recipeUrl),
        });
        loadedData.content = extendListWithRowId(loadedData.content);
        const newContent = await fillProducts({
          allIngredients: loadedData.content,
          changedIngredients: loadedData.content,
          onError,
          config: settings,
        });
        loadedData.content = newContent;
        setList(loadedData);
      };
      loadList();
    } else if (listId === 'newList') {
      const loadList = async () => {
        const list = await createEmptyList({ onError }); //
        setList(list);
      };
      loadList();
    } else if (listId) {
      const loadList = async () => {
        try {
          const loadedData = await fetchApiGet('list/getWrapper', {
            listId,
            populate: ['comments', 'tags', 'user'],
          });
          if (_.isArray(loadedData) && loadedData.length === 1) setList(loadedData[0]);
        } catch (error) {
          if (_.isFunction(onError)) onError(error);
          else console.error(error);
        }
      };
      loadList();
    }
  }, [listId, recipeUrl]);

  const setFunction = (param) => {
    console.log(`Set function of list launched.`);
    if (!param) return;
    if ('action' in param) {
      if (['modifyContent', 'addIngredients', 'rename'].includes(param.action)) {
        let content = [];
        let newList = list;
        if (_.isFunction(param.context)) {
          newList = { ...list, content: param.context(list.content ?? []) };
        } else if (_.isArray(param.context)) {
          if (param.action === 'addIngredients') {
            content = param.context;
          } else {
            content = (list.content ?? []).concat(param.context);
          }
        } else if (_.isObject(param.context)) {
          newList = param.context;
        }
        const evaluateChange = async () => {
          console.log(`Evaluating shopping list changes.`);
          const extendedShoppingList = await makeListChange({ oldList: list, newList, onError, config: settings, params: param?.params });
          if (extendedShoppingList) {
            // setList(_.cloneDeep(extendedShoppingList));
            const newFinalList = { ...extendedShoppingList };
            setList(newFinalList);
            setShowMessage({
              open: true,
              severity: 'success',
              message: intl.formatMessage({ id: 'useList.saved', defaultMessage: `List was saved.` }),
            });
            console.log(`Changes are evaluated. List age is ${new Date(newFinalList?.updatedOn).getTime()}`);
          }
        };
        evaluateChange();
      } else if (['replaceProduct'].includes(param.action)) {
        const newProduct = param?.context?.newProduct;
        const ingredient = { ...list.content.find((item) => item.rowId === param.context?.ingredient?.rowId) };
        if (!newProduct || !ingredient) return;
        const shopId = shopsLib.getShopNameFromUrl(newProduct?.productURL ?? newProduct?.productUrl);
        const oldProduct = list.content.find((item) => item.rowId === ingredient.rowId)?.[shopId];
        console.log(
          `Replacting product for ingredient ${ingredient.name}(${ingredient.rowId}) having product '${oldProduct?.name}' with product '${newProduct.name}'`
        );
        productChangedFeedback({ ingredient, newProduct, oldProduct, list, settings });
        const newRow = { ...ingredient, [shopId]: newProduct, version: ingredient.version + 1 };
        const newContent = list.content.map((item) => (item.rowId === ingredient.rowId ? newRow : item));
        const evaluateChange = async () => {
          //TODO - make change needs to identify change in product for specific rowId
          const newList = { ...list, content: newContent };
          const newShoppingList = await makeListChange({
            oldList: list,
            newList,
            onError,
            params: { suppressProductsReload: true },
            config: settings,
          });
          setList(newShoppingList);
          setShowMessage({
            open: true,
            severity: 'success',
            message: 
            intl.formatMessage({
              id: 'useList.productReplaced',
              defaultMessage: `Product selection for ingredient '{ingredientName}' successfully updated.`,
            }, {ingredientName: ingredient.name})
            ,
          });
        };
        evaluateChange();
      }
    } else {
      setList(param);
    }
  };

  return [list, setFunction];
}
