import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
  Divider,
  Icon,
  Button,
  Chip,
} from '@mui/material';
import { useState } from 'react';
import _ from 'lodash';
import useShops from '../utility/useShops';
import { FormattedMessage } from 'react-intl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';

export default function ListCard({ user, title, updatedOn, content, imagePath, onError, onClick, addToCartAction }) {
  const shopList = useShops({ onError });
  const [anchorEl, setAnchorEl] = useState(null);

  const contentGroupedByCategory = _.groupBy(content, (item) => item.categoryName);
  let estimatedPrice = 0;
  if (_.isArray(content) && _.isArray(shopList)) {
    content.forEach((item) => {
      let averageItemPrice = 0;
      let shopsWithPrice = 0;
      shopList.forEach((shop) => {
        if (item[shop['name']]?.price) {
          shopsWithPrice++;
          averageItemPrice += item[shop['name']].price;
        }
      });
      if (shopsWithPrice === 0) return;
      averageItemPrice = averageItemPrice / shopsWithPrice;
      estimatedPrice += averageItemPrice;
    });
    if (!_.isNaN(estimatedPrice)) estimatedPrice = `${parseFloat(estimatedPrice).toFixed(0)},-`;
    else estimatedPrice = '';
  }
  return (
    <Card
      sx={{
        ':hover': { boxShadow: 6 },
        boxShadow: 2,
        justifyContent: 'center',
        height: '100%',
      }}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex"  flexDirection="column" alignContent="space-between" height="100%">
        <Box>
          <Box onClick={onClick} sx={{ '&:hover': { cursor: 'pointer' } }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row">
                <Avatar sx={{ margin: '0.5em' }}>
                  {user?.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '')}
                </Avatar>
                <Box sx={{ margin: '0.5em' }}>
                  <Typography variant="body2">
                    {title} ({content.length})
                  </Typography>
                  <Typography variant="caption">{updatedOn}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#F0F0F0',
              padding: '0.2em',
              '&:hover': { cursor: 'pointer' },
            }}
            onClick={onClick}
          >
            {' '}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {content && (
                <Box
                  sx={{
                    width: '100%',
                    overflowY: 'hidden',
                    overflowX: 'auto',
                    height: '5em',
                    '&:hover': { cursor: 'pointer' },
                  }}
                  onClick={onClick}
                >
                  {Object.keys(contentGroupedByCategory ?? {}).map((item) => (
                    <Chip
                      label={`${item} (${contentGroupedByCategory[item].length})`}
                      sx={{ margin: '0.2em', height: '1.5em' }}
                      onClick={() => {}}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </CardContent>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt="auto">
          <Typography m={1}>cca {estimatedPrice}</Typography>
          <Box>
            <Button
              onClick={() => addToCartAction()}
              variant="contained"
              sx={{ position: 'relative', float: 'right', margin: '1em', borderRadius: '35px', height: '2em' }}
            >
              <ShoppingCartIcon sx={{ paddingRight: '0.5em' }} />
              <FormattedMessage id="listDetail.content.buy" defaultMessage="Buy" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
