import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { unitsState } from './atoms';
import { fetchApiGet } from './fetchApi';

export default function useUnits({ onError }) {
  const [units, setUnits] = useRecoilState(unitsState);  

  return units;
}
