import _ from 'lodash';

export const iglib = {};

iglib.reduceIngredientList = (ingredients) => {
  const result = [];
  ingredients.forEach((ingredient) => {
    var item = result.find(
      (existing) =>        
        iglib.isIngredientEqual(ingredient, existing)
    );
    if (item) {
      item.amount = Number(item.amount) + Number(ingredient.amount);
    } else {
      result.push({...ingredient});
    }
  });
  return result;
};

iglib.isIngredientEqual = (first, second) => {
  if(_.isNull(first) || _.isNull(second)) return false;
  //ingredient.recipeIngredientId == existing.recipeIngredientId && ingredient.unitId == existing.unitId
  return first.name === second.name && first.unitId === second.unitId;
}
