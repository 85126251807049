import { Card, CardContent, Typography, Box, Icon, Avatar, IconButton } from '@mui/material';
import { useRef } from 'react';
import _ from 'lodash';
import useShops from '../utility/useShops';
import usePrefferedShop from '../utility/usePrefferedShop';
import useUnits from '../utility/useUnits';
import { grey } from '@mui/material/colors';
import { iconColors } from '../lib/visualSettingsForCategories';
import CancelIcon from '@mui/icons-material/Cancel';

export default function IngredientCard({ rowData, onError, onClick, onRemove }) {
  const shopList = useShops({ onError });
  const units = useUnits({ onError });
  const name = rowData.name;
  const amount = rowData.amount;
  const unitId = rowData.unitId;
  let unitName = '';
  let averageRowPrice = 0;
  const [prefferedShop, setPrefferedShop] = usePrefferedShop({ onError });
  const prefferedShopName = prefferedShop?.name;
  const componentId = useRef(crypto.randomUUID());
  if (_.isArray(units)) {
    unitName = units?.find((item) => item.unitId === unitId)?.name;
  }
  if (_.isArray(shopList) && !_.isEmpty(rowData)) {
    let shopsWithPrice = 0;
    shopList.forEach((shop) => {
      if (rowData[shop['name']]?.price) {
        shopsWithPrice++;
        averageRowPrice += rowData[shop['name']].calculatedTotal;
      }
    });
    averageRowPrice = averageRowPrice / shopsWithPrice;
    if (!_.isNaN(averageRowPrice)) averageRowPrice = `~${parseFloat(averageRowPrice).toFixed(0)},-`;
    else averageRowPrice = '';
  }
  return (
    <Card
      sx={{
        ':hover': { boxShadow: 6 },
        justifyContent: 'center',
        position: 'relative',
        padding: '0px',
      }}
    >
      <CardContent sx={{padding: '8px'}}>
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '0px', justifyContent: 'space-between', }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '0px', '&:hover': { cursor: 'pointer' }}} onClick={onClick} >
            <Avatar sx={{ bgcolor: iconColors[rowData.categoryName] ?? grey[100] }} aria-label="recipe">
              {name.substring(0, 1)}
            </Avatar>
            <Box
              sx={{
                fontSize: '0.1rem',
                display: 'flex',
                width: '100%',
                marginLeft: '5px',
                flexDirection: 'column',
                '&:hover': { cursor: 'pointer' },
              }}
            >
              <Typography variant="body2">{`${name}`}</Typography>
              <Typography variant="body2" color="text.secondary">
                {`${amount ?? ''}${unitName ?? ''}`}
              </Typography>
            </Box>

          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <IconButton
              sx={{ marginTop: '0px', paddingTop: '0px', paddingRight: '0px' }}
              onClick={() => onRemove()}
            >
              <CancelIcon />
            </IconButton>
            {averageRowPrice && (
              <Typography variant="body2" sx={{ textAlign: 'right' }}>{`${averageRowPrice}`}</Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
