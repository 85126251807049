import _ from 'lodash';
import React from 'react';
// import Autosuggest from 'react-autosuggest';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import useFetchApi from '../utility/useFetchApi';

function AutocompleteField({ label, url, getUrlParams, unitList, value, setValue, onError }) {
  const [containsFilter, setContainsFilter] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const setDebouncedContainsFilter = React.useRef(_.debounce((value) => setContainsFilter(value), 300));
  const data = useFetchApi(url, getUrlParams(containsFilter), [], null, onError);

  const getLabel = (option) => {
    let result = '';
    if (option.estimatedAmount) result = option.estimatedAmount + ' ';
    if (_.isArray(option.unit) && option.unit.length > 0) {
      if (option.unit[0] == 0) {
        if (option.unit.length > 1) result += getUnitNameByID(option.unit[1]) + ' ';
      } else {
        result += getUnitNameByID(option.unit[0]) + ' ';
      }
    }
    result += option.name;
    result += option.categoryName ?  ` (${option.categoryName})` : ""
    return result;
  };

  // const customItem = {
  //   categoryName: 'NoCategory',
  //   estimatedAmount: 1,
  //   unit: [14, 5, 2],
  //   recipeIngredientId: null,
  //   probability: 1,
  //   name: !_.isNil(containsFilter) ? containsFilter : '',
  // };

  const getUnitNameByID = (id) => {
    const result = unitList.find((element) => element?.unitId == id);
    if (result) return result['name'];
  };

  let extendedData = data;
  // if (_.isArray(data)) {
  //   if (
  //     //check wheter to add custom item: is not already present, filter is not empty and the same item was not already found
  //     data.filter((item) => (item.recipeIngredientId == null ? true : false)).length == 0 &&
  //     !_.isNil(containsFilter) &&
  //     data.filter((item) => item.name == customItem.name).length == 0
  //   ) {
  //     data.push(customItem);
  //   } else {
  //     if (_.isNil(containsFilter)) {
  //       extendedData = data.map((item) => (item.recipeIngredientId == null ? null : item));
  //     } else {
  //       extendedData = data.map((item) => (item.recipeIngredientId == null ? customItem : item));
  //     }
  //   }
  // }

  const filterOptions = (options, { inputValue }) => {
    return options; //.filter((item) => item.freeText == inputValue))
  };

  return (
    <Autocomplete
      id="ingredient-autocomplete"
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys={false}
      label={label}
      sx={{ m: 2 }}
      value={value}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.name == value}
      options={(extendedData || data || []).map((x) => ({ ...x, label: x.name}))}
      // sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={label} />}
      filterOptions={filterOptions}
      renderOption={(props, option) => (
        <div>
        <Box key={option.name} component="li" {...props}>                    
          {getLabel(option)}
          {option.recipeIngredientId === null && (
            ` (new ingredient)`
          )}
        </Box>
        </div>
      )}
      onInputChange={(ev, val) => {
        //console.log('INPUT', val);
        setDebouncedContainsFilter.current(val);
        setInputValue(val);
      }}
      onChange={(ev, val, reason) => {
        //console.log('CHANGE', val, reason);
        setValue(val);    
        setInputValue("");
      }}
    />
  );
}

export default AutocompleteField;
