import React, { useEffect } from 'react';
import useUser from '../../utility/useUser';
import { useRecoilState } from 'recoil';
import { serverListState, settingsServerStoredState } from '../../utility/atoms';

function EventSourceDataProvider() {
  const [userSettingsData, setUserSettingsData] = useUser({ loginRequired: false });
  const [serverList, setServerList] = useRecoilState(serverListState);
  const [serverStoredSettings, setServerStoredSettings] = useRecoilState(settingsServerStoredState);


  useEffect(() => {
    if (userSettingsData?.loginStatus !== "loggedIn") return;
    const apiBase = process.env.REACT_APP_API_BASE || `${window.location.origin}/api`;
    const url = `${apiBase}/events/${userSettingsData.userId}`;
    console.log(`Initializing event source towards URL ${url}`);
    const source = new EventSource(url);
    source.addEventListener("open", (event) => {
          console.log(event);
          console.log(`Current event source status is ${source.readyState}`);
        });
    
    const onMessageHandler = (message) => {
      
      console.log(`Recieved data with length (${message?.data.length}) from eventSource.`)
      const parsedData = JSON.parse(message?.data);      
      console.log(`Message type detected is (${parsedData.messageType}).`)
      if(parsedData.messageType === "UserSettingsChanged"){
        setServerStoredSettings(parsedData?.content);
      }else if(parsedData.messageType === "ListChanged"){
        setServerList(parsedData);
      }
      
    }

    source.onmessage = onMessageHandler;
      
    //handleError;
    source.onerror = (err) => {
          console.debug(`Error occured in  event source. Current status is ${source.readyState}. Error ${err}`);
    }
    return () => {
      source.close();
      source.removeEventListener('message', onMessageHandler);
      console.log(
        `Removing event listener from ${url}. Status is ${source.readyState === 2 ? 'closed' : 'not closed yet'}.`
      );
    };
  }, [userSettingsData?.loginStatus]);

  return <div />;
}

export default EventSourceDataProvider;
