import _ from 'lodash';
import { fetchApiPost } from './fetchApi';

export async function makeListChange({ oldList, newList, onError, config, params = {} }) {
  try {
    if (!_.isFunction(params.getRowId)) params.getRowId = (item) => item.rowId;
    const oldValues = oldList.content ?? [];
    const newValues = newList.content ?? [];
    const contentChanges = detectContentChanges(oldValues, newValues, params.getRowId);
    const attributeChanges = detectAttributeChanges(oldList, newList);
    console.log(
      `Detected (${Object.keys(attributeChanges).length}) attribute changes and (${
        contentChanges.totalAmount
      }) changed rows.`
    );
    const changedRows = (contentChanges.added ?? []).concat(contentChanges.changed ?? []);
    if (changedRows.length > 0) {
      if (!params.suppressProductsReload) {
        const products = await loadProducts({changedRows, onError, config});
        if(products) changedRows.forEach((item) => {
          const selectedProducts = getProductForIngredient(item, products);
          const currentIndex = newValues.findIndex((search) => params.getRowId(search) === params.getRowId(item));
          newValues[currentIndex] = assignProductToIngredient(item, selectedProducts);
        });
      } else {
        console.log('Suppressing products reload on shopping list change.');
      }
    }
    let resultList = { ...newList, content: newValues };
    if (contentChanges.deleted?.length > 0) {
      const existingDeleted = { ...resultList.deletedRows };
      const newDeleted = existingDeleted ?? {};
      contentChanges.deleted.forEach((item) => {
        newDeleted[item.rowId] = { deletedOn: new Date().getTime() };
      });
      resultList.deletedRows = newDeleted;
    }
    if (!params.suppressPersist && (contentChanges.totalAmount > 0 || Object.keys(attributeChanges).length > 0)) {
      resultList.version = _.isNumber(resultList.version) ? Number(resultList.version) + 1 : 1;
      resultList = await persistList(resultList, onError);
    }
    if(params.returnOnChangeOnly && contentChanges.totalAmount == 0 && Object.keys(attributeChanges).length == 0){
      return null;
    }else{
      return resultList;
    }    
  } catch (error) {
    console.log(error);
    if (_.isFunction(onError)) onError(error);
  }
}

export async function resetProducts(list, onError, config) {
  const products = await loadProducts({changedRows: list.content, onError, config});
  const listCopy = { ...list };
  listCopy.content = [...list.content];
  listCopy.content.forEach((item) => {
    const selectedProducts = getProductForIngredient(item, products);
    const currentIndex = listCopy.content.findIndex((search) => search.rowId === item.rowId);
    listCopy.content[currentIndex] = assignProductToIngredient(item, selectedProducts);
  });
  const resultList = await persistList(listCopy);
  return resultList;
}

export function extendListWithRowId(content){
  const newContent = (content ?? []).map((item) => {
    return { ...item, rowId: crypto.randomUUID(), version: 0 };
  })
  return newContent; 
}

export async function loadProducts({changedRows, onError, config}) {
  if (changedRows.length > 0) {
    const products = await fetchApiPost('pyapi/getProductsForIngredients', {
      ingredients: changedRows,
      config: { ...reduceConfigForProductsLoading(config), maxProductsPerIngredient: 1 },
    }, {}, onError);
    return products;
  }
}

export function reduceConfigForProductsLoading(config){
  if(_.isObject(config)){
    return {
      pricePrefference: config.pricePrefference?.value ?? 50,
      adventurous: config.adventurous?.value ?? 50
    }
  }
}

function getProductForIngredient(ingredient, products) {
  let selectedProducts = {};
  Object.keys(products).forEach((shopId) => {
    const productsByShop = products[shopId].filter((product) => {
      return product.recipeIngredientName === ingredient.name;
    });
    selectedProducts[shopId] = productsByShop;
  });
  return selectedProducts;
}

function assignProductToIngredient(ingredient, products) {
  const newItem = { ...ingredient };
  Object.keys(products).forEach((shopId) => {
    if (products[shopId].length > 0) {
      newItem[shopId] = products[shopId][0];
    }
  });
  return newItem;
}

function detectAttributeChanges(oldList, newList) {
  const attributesToIgnore = ['createdOn', 'lastUpdatedOn', 'content'];
  let changes = {};
  Object.keys(newList).forEach((attribute) => {
    if (attributesToIgnore.includes(attribute)) return;
    if (oldList[attribute] != newList[attribute]) {
      changes[attribute] = `${oldList[attribute]} -> ${newList[attribute]}`;
    }
  });
  return changes;
}

function detectContentChanges(oldValues, newValues, getRowId) {
  let changes = {};
  changes.totalAmount = 0;
  oldValues.forEach((oldIngredient) => {
    const row = newValues.filter((newIngredient) => getRowId(newIngredient) === getRowId(oldIngredient));
    if (row.length === 1) {
      if (row[0].version !== oldIngredient.version) {
        _.isNil(changes.changed) ? (changes.changed = [row[0]]) : changes.changed.push(row[0]);
        changes.totalAmount++;
      }
    } else if (row.length === 0) {
      //row was removed
      _.isNil(changes.deleted) ? (changes.deleted = [oldIngredient]) : changes.deleted.push(oldIngredient);
      changes.totalAmount++;
    }
  });
  newValues.forEach((newIngredient) => {
    const ingredientExists = oldValues.filter((oldIngredient) => getRowId(newIngredient) === getRowId(oldIngredient));
    if (ingredientExists.length === 0) {
      _.isNil(changes.added) ? (changes.added = [newIngredient]) : changes.added.push(newIngredient);
      changes.totalAmount++;
    }
  });
  return changes;
}

export async function createEmptyList({ name, onError, rootTagId }) {
  try {
    const list = getDefaultList({ name, rootTagId });
    console.log(`Creating new shopping list ${JSON.stringify(list)}`);
    const persistedList = await persistList(list, onError);  
    return persistedList;
  } catch (error) {
    console.log(error);
    if (_.isFunction(onError)) {
      onError(error);
    } else {
      throw error;
    }
  }
}

export async function fillProducts({allIngredients, changedIngredients, onError, config, params = {}}){
  if (!_.isFunction(params.getRowId)) params.getRowId = (item) => item.rowId;
  const products = await loadProducts({changedRows: changedIngredients, onError, config});
  if(products) changedIngredients.forEach((item) => {
    const selectedProducts = getProductForIngredient(item, products);
    const currentIndex = allIngredients.findIndex((search) => params.getRowId(search) === params.getRowId(item));
    allIngredients[currentIndex] = assignProductToIngredient(item, selectedProducts);
  });
  return allIngredients;
}

export async function removeList(list, onError) {
  try {
    if (list.listId) {
      list.deletedDate = new Date();
      await fetchApiPost('list/saveWrapper', list, {}, onError);      
      localStorage.removeItem(getListStorageId(list.listId));     
      console.log(`Shopping list '${list.listId}' removed`);
      return true;
    }
  } catch (error) {
    console.log(error);
    if (_.isFunction(onError)) onError(error);
    else throw error;
  }
}

export async function addIngredients({list, newValues, config, onError}) {
  if (_.isEmpty(newValues)) return;
  try {
    console.log(`Adding ${newValues.length} ingredinets into shopping list.`);
    const [normalizedValues] = await Promise.all([
      await fetchApiPost('unit/normalizeUnits', { ingredientList: newValues }),
    ]);
    const normalizedValuesWithId = normalizedValues.map((item) => {
      return { ...item, rowId: crypto.randomUUID() };
    });
    const mergedContent = (list.content ?? [])
      .map((x) => {
        return { ...x };
      })
      .concat(normalizedValuesWithId);
    const reducedIngredients = reduceIngredients(mergedContent);
    //TODO add newListParam
    const newList = {...list};
    newList.content = reducedIngredients;
    return await makeListChange({oldList: list, newList, onError, config, params: { getRowId: (item) => item.name }});
  } catch (error) {
    console.log(error);
    if (_.isFunction(onError)) {
      onError(error);
    } else {
      throw error;
    }
  }
}

export function getDefaultList({ name = 'Shopping list', rootTagId = 1 }) {
  return {
    name,
    tempId: crypto.randomUUID(),
    updatedOn: new Date(),
    content: [],
    comments: [],
    tags: [],
    version: 1,
    removedRows: {},
    rootTagId,
    isPublic: false,
  };
}

export async function getAvailableLists(onFailed, options = {}) {
  return await fetchApiPost('list/getListOfLists', options, {}, onFailed);
}

export async function getListsFromServer(listId) {
  const list = await fetchApiPost('list/getWrapper', { listId });
  if (list.length == 1) {
    return list[0];
  }
}

function reduceIngredients(ingredients) {
  const result = [];
  ingredients.forEach((ingredient) => {
    var item = result.find(
      (existing) =>
        //ingredient.recipeIngredientId == existing.recipeIngredientId && ingredient.unitId == existing.unitId
        ingredient.name === existing.name
    );
    if (item) {
      if (ingredient.unitId === item.unitId) {
        item.amount = Number(item.amount) + Number(ingredient.amount);
        item.version = item.version + 1;
      } else {
        console.log(`Conversion for ${item.name} ignored. Source is ${item.unitId}, target is ${ingredient.unitId}`);
        item.amount = Number(item.amount) + Number(ingredient.amount);
        item.version = item.version + 1;
      }
    } else {
      result.push(ingredient);
    }
  });
  return result;
}


async function persistList(list, onError) {
  console.log(
    `Persisting shopping list list with ID '${list.listId}'. Version is ${list.version}`
  );
  const result = await fetchApiPost('list/saveWrapper', list, {}, onError);
  if (result) {
    //localStorage.setItem(getListStorageId(result.listId), JSON.stringify(result));
    console.log(
      `Shopping list with ID '${result.listId}' synchoronized with server and localy. Version is ${result.version}`
    );

    return result;
  }
}

function getListStorageId(listId) {
  return `list_${listId}`;
}

// async function getListsFromServer(listId = getActiveListId()) {
//   const list = await fetchApiPost('list/getWrapper', { listId });
//   if (list.length == 1) {
//     return list[0];
//   }
// }
