import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, Button, ListItemAvatar, ListItemText } from '@mui/material';
import { green, red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import React from 'react';
import { fetchApiPost } from '../utility/fetchApi';
const _ = require('lodash');

export default function ShoppingListShareDialog({ list, open, onClose, onFailed}) {
  const listId = list?.listId;
  const [existingShares, setExistingShares] = React.useState([]);
  const [forceReload, setForceReload] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const onError = (error) => {
    console.log(JSON.stringify(error));
    setErrorMessage(error.message);
  };  

  const loadData = React.useCallback(async (aShoppingListId) => {
    return await fetchApiPost(
      'listShare/selectShareWrapper',
      {
        listId: aShoppingListId,
        populate: ['user'],
      },
      {},
      onFailed
    );
  }, []);

  React.useEffect(() => {
    const loadShares = async() => {
      if(!listId || list?.isPublic) return;      
      const loadedData = await loadData(listId);
      if (loadedData && _.isArray(loadedData)) {
        console.log(`Dowloaded share mapping for shopping list (${listId}). Existing shares are ${JSON.stringify(loadedData)}`)
        setExistingShares(loadedData);
      }
    }
    loadShares();    
  }, [forceReload, listId, loadData]);

  const handleClose = () => {
    setErrorMessage("");
    onClose();
  };

  const deleteShareHandler = async (listShareId) => {
    await fetchApiPost(
      'listShare/removeWrapper',
      {
        listShareId,
      },
      {},
      onError
    );
    setForceReload(forceReload + 1);
  };

  const createShareHandler = async () => {
    await fetchApiPost(
      'listShare/saveWrapper',
      {
        listId,
        email,
      },
      {},
      onError
    );
    setEmail('');
    setForceReload(forceReload + 1);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Share your shopping list</DialogTitle>
      <Box padding={3} pt={0} sx={{minWidth: '400px'}}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <TextField
            autoFocus
            value={email}
            margin="dense"
            label="Email address"
            type="em"
            error={errorMessage !== ""}
            helperText={errorMessage}
            fullWidth
            onChange={(event) => setEmail(event.target.value)}
          />

          <Box
            onClick={() => createShareHandler()}
            sx={{ padding: 1, display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }}
          >
            <Button variant='contained'>Share</Button>
          </Box>
        </Box>
        <Box sx={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '90%' }}></Box>
        {existingShares.filter((item) => item.userId).length > 0 && (
          <List sx={{ padding: 2 }}>
            <DialogContentText>Shopping list is already shared with:</DialogContentText>
            {existingShares
              .filter((item) => item.userId)
              .map((option) => (
                <ListItem
                  key={option.listShareId}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteShareHandler(option.listShareId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={option.user ? `${option.user.name}` : option.userId}
                    secondary={option.user ? `${option.user.email}` : ''}
                  />
                </ListItem>
              ))}
          </List>
        )}
        {existingShares.filter((item) => item.userId == null).length > 0 && (
          <List sx={{ padding: 2 }}>
            <DialogContentText>Pending invitations:</DialogContentText>
            {existingShares
              .filter((item) => item.userId == null)
              .map((option) => (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteShareHandler(option.listShareId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: red[100], color: red[600] }}>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={option.email} />
                </ListItem>
              ))}
          </List>
        )}
        {/*<Box sx={{ width: '100%', textAlign: 'right' }}>
          <Button variant="outlined" onClick={() => handleClose()}>
            Close
          </Button>
              </Box>*/}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
