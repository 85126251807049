import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import AppLayout from '../layout/AppLayout';
import { Box, Button, Typography } from '@mui/material';
import useShowMessage from '../hook/useShowMessage';
import useUser from '../utility/useUser';
import { fetchApiPost } from '../utility/fetchApi';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const clientId = '744242036623-6d3pb67qmk6e1nncam780m54io5q0vcg.apps.googleusercontent.com';

export default function LoginPage() {
  const intl = useIntl();
  const [user, setUser] = useUser({ loginRequired: false });
  const history = useHistory();
  const [showMessage, setShowMessage] = useShowMessage();
  const onError = (error) => {
    console.log(JSON.stringify(error));
    setShowMessage({ open: true, severity: 'error', message: error.message });
  };

  const onSuccess = async (result) => {
    console.log(`Login from Google succeeded with result: ${JSON.stringify(result)}.`);
    console.log(`Sending login validation into backend.`);
    fetchApiPost('user/googleLogin', result)
      .then(async (resp) => {
        resp = { ...resp, loginStatus: 'loggedIn' };
        console.log('Authenticated user response ' + JSON.stringify(resp));
        //setShowMessage({ open: true, severity: 'success', message: 'You were successfully logged in.' });
        setUser(resp);
        history.push('home');            
      })
      .catch(onError);
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
        <Box
         sx={{
            backgroundColor: 'white',
            minHeight: 'calc(100vh)',
            }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: 'calc(-180px + 100vh)',
          backgroundColor: 'white'
        }}
      >
        <img src={'../../images/YumDroid.png'} width="256px" alt={`YouDroid`} />
        <Typography margin={2} sx={{ marginBottom: '60px' }}>
          <FormattedMessage id='login.infoMessage' defaultMessage='To be able to use the website, you need to login. In case you are not registered yet, you need to wait after login for an approval of your access. Access is currently allowed only for invited users.'/>
        </Typography>
        <div align={'center'} margin={2}>
          <GoogleLogin onSuccess={onSuccess} onError={onError} auto_select useOneTap width="320px" />
        </div>
        <div align={'center'} margin={2}>
          {window.location.origin.includes('http://localhost') && (
            <Button
              sx={{ margin: '24px', width: '320px' }}
              variant="outlined"
              onClick={() =>
                onSuccess({
                  fake: true,
                  sessionId: 'demo',
                })
              }
            >
              <FormattedMessage id='login.developerLogin' defaultMessage='Developer Login'/>              
            </Button>
          )}
        </div>
      </Box>
      </Box>
    </GoogleOAuthProvider>
  );
}
