import stableStringify from 'json-stable-stringify';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { fetchApiGet } from './fetchApi';
const _ = require('lodash');

export default function useFetchApi(url, params = {}, defValue = [], onLoaded, onFailed) {
  const [value, setValue] = React.useState(defValue);
  const history = useHistory();

  const { reloadToken, setLoadingState, resetOnParamsChange, ...restParams } = params;

  async function loadValue() {
    if (!url) return;
    if (setLoadingState) setLoadingState(true);
    if (resetOnParamsChange) setValue(defValue);
    try {
      setValue(await fetchApiGet(url, restParams));
      if (setLoadingState) setLoadingState(false);
      if (onLoaded) onLoaded();
    } catch (error) {
      console.log(history.location);
      if (error.status == 401 && history.location.pathname != '/authentication/login') {
          history.push('/authentication/login');        
      }else if(_.isFunction(onFailed)){
        onFailed(error);
      }               
    }
  }
  React.useEffect(() => {
    setValue(defValue);
  }, [reloadToken]);
  React.useEffect(() => {
    loadValue();
  }, [url, reloadToken, stableStringify(restParams)]);

  return value;
}
