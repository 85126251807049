import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, Button, ListItemAvatar, ListItemText } from '@mui/material';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import React from 'react';
import useTags from '../utility/useTags';

export default function TagSettingsDialog({ open, onClose, onFailed }) {
  const [tags, setTags] = useTags({ onError: onFailed });

  const [forceReload, setForceReload] = React.useState(0);
  const [newTag, setNewTag] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const onError = (error) => {
    console.log(JSON.stringify(error));
    setErrorMessage(error.message);
  };

  const handleClose = () => {
    setErrorMessage('');
    onClose();
  };

  const deleteTag = async (tagToDelete) => {
    const newTags = tags.map(item => item.tagId === tagToDelete ? {...item, deletedDate: new Date()} : item)
    setTags(newTags);
  };

  const createTag = async () => {
    const newTags = [...tags, newTag]
    setTags(newTags);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Manage tags dialog</DialogTitle>
      <Box padding={3} pt={0} sx={{ minWidth: '350px' }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <TextField
            autoFocus
            value={newTag}
            margin="dense"
            label="New tag"
            type="em"
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
            onChange={(event) => setNewTag(event.target.value)}
          />

          <Box                      
            sx={{ padding: 1, display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }}
          >
            {(newTag && !tags.find(value => value.name === newTag)) && <Button variant="contained" onClick={() => createTag() }>Create</Button>}
            {(!newTag || tags.find(value => value.name === newTag)) && <Button variant="contained" disabled onClick={() => createTag() }>Create</Button>}
          </Box>
        </Box>
        <Box sx={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '90%' }}></Box>
        {tags.filter((item) => !item.isPublic).length > 0 && (
          <List sx={{ padding: 2 }}>
            <DialogContentText>Private tags</DialogContentText>
            {tags
              .filter((item) => !item.isPublic)
              .map((option) => (
                <ListItem
                  key={option.tagId}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteTag(option.tagId)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={option.name} />
                </ListItem>
              ))}
          </List>
        )}
        {tags.filter((item) => item.isPublic).length > 0 && (
          <List sx={{ padding: 2 }}>
            <DialogContentText>Public tags</DialogContentText>
            {tags
              .filter((item) => item.isPublic)
              .map((option) => (
                <ListItem key={option.tagId}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={option.name} />
                </ListItem>
              ))}
          </List>
        )}

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
