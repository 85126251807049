import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useRef, useState } from 'react';
import { Grow } from '@mui/material';
import { messageHistoryState } from '../utility/atoms';
import { useRecoilState } from 'recoil';
import _ from 'lodash';

function GrowTransition(props) {
  return <Grow {...props} />;
}

export default function SystemMessageHandler() {
  const [messageHistory, setMessageHistory] = useRecoilState(messageHistoryState);
  const latestMessage = messageHistory.length - 1 >= 0 ? messageHistory[messageHistory.length - 1] : {};  
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if(!_.isEmpty(latestMessage)){
      setOpened(true);
    }
  }, [latestMessage.messageId])
  

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{"marginBottom": "40px"}}
      open={opened}
      autoHideDuration={4000}
      TransitionComponent={GrowTransition}
      key={latestMessage?.messageId}
      onClose={() => {
        setOpened(false)
      }}
    >
      <Alert severity={latestMessage?.severity} variant="filled" sx={{ width: '100%' }}>
        {latestMessage?.message}
      </Alert>
    </Snackbar>
  );
}
