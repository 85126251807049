//{"listFilter": {"selectedTags":[]}, "activeShoppingListId":11,"prefferedShopId":1}

import { APP_VERSION } from "../utility/const";
import { fetchApiPost } from '../utility/fetchApi';

const SETTIGS_STORAGE_KEY = 'settings';

export async function persistSettings(settings, params){
    persistSettingsLocal(settings);    
    if(!params?.suppressServerSave){
        const result = await persistServerSettings(settings);    
    }
}

export function getCachedSettings(){
    const settings = localStorage.getItem(SETTIGS_STORAGE_KEY);
    if(settings && settings !== 'undefined'){
        return JSON.parse(settings);
    }else {
        const defaultSettings = getDefaultSettings();
        persistSettingsLocal(defaultSettings);
        return defaultSettings;        
    }
}

async function persistServerSettings(settings){    
    const serverSettings = {};
    Object.keys(settings ?? {}).forEach(settingsKey => {
        if(settings[settingsKey].scope === 'server'){
            serverSettings[settingsKey] = settings[settingsKey]
        }
    })
    const serverSettingsResult = await fetchApiPost('userSettings/saveUserSettingsWrapper', serverSettings);
}

export async function getServerStoredSettings(){
    const serverSettingsResult = await fetchApiPost('userSettings/getUserSettingsWrapper');
    return serverSettingsResult?.content;
}

function persistSettingsLocal(settings){
    localStorage.setItem(SETTIGS_STORAGE_KEY, JSON.stringify(settings));
}

export function mergeServerSettings(serverSettings, currentSettings){
    const localSettings = {};
    Object.keys(currentSettings).forEach(key => {
        if(currentSettings[key]?.scope === 'local'){
            localSettings[key] = currentSettings[key];
        }
    })
    const newSettings = {...localSettings, ...serverSettings};
    return newSettings;
}

export function getDefaultSettings(){
    return {
        appVersion: {scope: "local", value: APP_VERSION},
        listFilter: {scope: "local", value: {"selectedTags":[]}},
        clientId: {scope: "local", value: crypto.randomUUID()}
    }
}
