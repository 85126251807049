import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { shopsState } from './atoms';
import { fetchApiGet } from './fetchApi';
import useSettings from '../hook/useSettings';
import { ShopConnectStatus } from '../lib/shopsLib.ts';

export default function useShops({ onError }) {
  const [shops, setShops] = useRecoilState(shopsState);
  const [settings, setSettings] = useSettings();  

  useEffect(() => {
    const calculateState = async () => {
      const resultShops = [];
      //to prevent infinite loop, count changes
      let changes = 0;
      shops?.forEach((shop) => {        
        if (shop.connectSupported) {
          if (settings?.shopConfiguration?.value?.[shop.shopId]?.username) {
            if(shop.shopConnectStatus !== ShopConnectStatus.Configured) changes++;              
            resultShops.push({...shop, shopConnectStatus: ShopConnectStatus.Configured })
          } else {
            if(shop.shopConnectStatus !== ShopConnectStatus.NotConfigured) changes++;                      
            resultShops.push({...shop, shopConnectStatus: ShopConnectStatus.NotConfigured })
          }
        } else {
          if(shop.shopConnectStatus !== ShopConnectStatus.NotSupported) changes++;                               
          resultShops.push({...shop, shopConnectStatus: ShopConnectStatus.NotSupported })
        }
      });
      if(changes > 0){
        setShops(resultShops);
      }
    };
    calculateState();
  }, [settings, shops]);

  return shops;
}
