import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { settingsState } from '../utility/atoms';
import { getDefaultSettings, persistSettings } from '../lib/settings';

export default function useSettings() {
  const [settings, setSettings] = useRecoilState(settingsState);

  const handleUpdate = (value) => {
    if (value === null) {
      value = getDefaultSettings();
    } else if (value.value) {
      persistSettings(value.value, value.param);
      setSettings(value.value);
    } else {
      persistSettings(value);
      setSettings(value);
    }
  };

  return [settings, handleUpdate];
}
