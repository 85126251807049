import { Box, FormControl, InputLabel, MenuItem, Select, Slider, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import _ from 'lodash';
import useSettings from '../hook/useSettings';
import { useIntl, FormattedMessage } from 'react-intl';

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 100,
    label: '100%',
  },
];

export default function UserPreferenceForm({ onError }) {
  const [settings, setSettings] = useSettings();  
  const intl = useIntl();
  const [pricePrefference, setPricePrefference] = useState(50);
  const [adventurous, setAdventurous] = useState(50);  

  useEffect(() => {
    if (!_.isEmpty(settings)) {
      setPricePrefference(settings.pricePrefference?.value);
      setAdventurous(settings.adventurous?.value);
    }
  }, [settings?.pricePrefference, settings?.adventurous]);

  return (
    <Box>             
      <FormControl fullWidth sx={{ marginLeft: '16px', marginBottom: '16px' }}>
        <Box sx={{ width: 300 }}>
          <Typography id="input-slider" sx={{}} gutterBottom>
          <FormattedMessage id='userSettings.usePreferenceForm.PricePrefference' defaultMessage='Price prefference'/>{' '}({pricePrefference})
          </Typography>
          <Slider
            aria-labelledby="input-slider"
            value={pricePrefference ?? 50}
            getAriaValueText={(arg) => {
              return `${arg}`;
            }}
            step={1}
            valueLabelDisplay="auto"
            marks={marks}
            onChange={(event) =>
              setSettings({ ...settings, pricePrefference: { value: event.target.value, scope: 'server' } })
            }
          />
        </Box>
      </FormControl>
      <FormControl fullWidth sx={{ marginLeft: '16px' }}>
        <Box sx={{ width: 300 }}>
          <Typography id="input-slider-adventurous" sx={{}} gutterBottom>
          <FormattedMessage id='userSettings.usePreferenceForm.Adventurous' defaultMessage='Adventurous'/>{' '}({adventurous})
          </Typography>
          <Slider
            aria-labelledby="input-slider-adventurous"
            value={adventurous ?? 50}
            getAriaValueText={(arg) => {
              return `${arg}%`;
            }}
            step={1}
            valueLabelDisplay="auto"
            marks={marks}
            onChange={(event) =>
              setSettings({ ...settings, adventurous: { value: event.target.value, scope: 'server' } })
            }
          />
        </Box>
      </FormControl>      
    </Box>
  );
}
