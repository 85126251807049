import { atom } from "recoil";

import messagesDefinition from "../i18n/messages";

export const localeState = atom<string>({
    default: "en",
    effects: [
        ({ setSelf, onSet }) => {
            let navigatorLanguage = navigator.language;
            if (navigatorLanguage.includes("-")) {
                navigatorLanguage = navigatorLanguage.split("-")[0];
            }

            let locale =
                localStorage.getItem("selectedLocale") || navigatorLanguage;
            if (!(locale in messagesDefinition)) {
                locale = "en";
            }
            setSelf(locale);

            onSet((value) => {
                if (value == navigatorLanguage) {
                    localStorage.removeItem("selectedLocale");
                } else {
                    localStorage.setItem("selectedLocale", value);
                }
            });
        },
    ],
    key: "locale",
});
