import Typography from '@mui/material/Typography';
import AppLayout from '../layout/AppLayout';
import { useHistory } from 'react-router-dom';
import { Box, Button, Icon } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export default function BrowserExtensionPage() {
  const history = useHistory();

  return (
    <AppLayout title="YumDroid" mainIconMeaning="back">
      <Typography variant="h6" component="div" m={2}>
        <FormattedMessage id="extension.header" defaultMessage="Google Chrome / Microsoft Edge browser extension" />
      </Typography>
      <Typography m={2}>
        <FormattedMessage
          id="extension.explanation.paragraph1"
          defaultMessage="To be able to fully use Yumdroid application, you need to install Chrome / Edge plugin. Plugin will help you
        to move products you selected into shopping basket of selected online shop. We found no plugin installed."
        />
      </Typography>
      <Typography m={2}>
        <FormattedMessage
          id="extension.explanation.paragraph2"
          defaultMessage="To do so, you need to use desktop version of Google Chrome browser or Miscrosoft Edge broser. Other brosers are
        not supported yet. Please remember there is no support for mobile Google Chrome application to install plugins.
        You can install it clicking the button bellow"
        />

        <Box>
          <Button
            onClick={() =>
              (window.location.href =
                'https://chrome.google.com/webstore/detail/zradloapp-chrome-extensio/cdmmcijcdhcbpelbllpmicgneijjlklp?hl=cs&authuser=1')
            }
            variant="contained"
            sx={{
              alignContent: 'center',
              margin: '15px',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <FormattedMessage id="extension.install" defaultMessage="Install browser plugin" />
          </Button>
        </Box>
      </Typography>
    </AppLayout>
  );
}
