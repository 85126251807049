import _ from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { tagsState } from './atoms';
import {  fetchApiPost } from './fetchApi';

export default function useTags({ onError }) {
  const [tags, setTags] = useRecoilState(tagsState);
  useEffect(() => {
    if(!_.isEmpty(tags)) return;
    const loadTags = async () => {
      try{
        const loadedData = await fetchApiPost('tag/getMyTags', {}, {}, onError);
        if (_.isArray(loadedData)) {
          setTags(loadedData);
        }
      } catch(error){
        console.log(error);
        if(_.isFunction(onError)) onError(error);
      }
    };
    loadTags();
  }, []);

  const refreshFunction = (newTags) => {
    const saveData = async (newTags) => {
      for (let newTag of newTags) {
        if (!tags.find((oldTag) => oldTag.tagId === newTag.tagId)) {
          const newTagSaved = await fetchApiPost('tag/saveTagWrapper', {name: newTag});
          setTags([...tags, newTagSaved]);
        }
        if (!tags.find((oldTag) => oldTag.deletedDate === newTag.deletedDate)) {
          await fetchApiPost('tag/saveTagWrapper', newTag);
          const newTags = tags.filter(item => newTag.tagId !== item.tagId)
          setTags(newTags);
        }
      }
    };
    saveData(newTags);
  };

  return [tags, refreshFunction];
}
