import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

export default function ListMenu({ items, onClose }) {
  return (
    <List>
      {items.map((item, key) => {
        const Icon = item.icon;
        return (
          <ListItem
            key={key}
            button
            onClick={() => {
              item.onClick();
              if (onClose) onClose();
            }}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        );
      })}
    </List>
  );
}
